export enum USER_STATUS {
  WAIT = "WAIT",
  NORMAL = "NORMAL",
  DORMANT = "DORMANT",
  WITHDRAW = "WITHDRAW",
  STOP = "STOP",
}

export enum USER_STATUS_LABELS {
  WAIT = "가입 대기",
  NORMAL = "가입 완료",
  DORMANT = "휴면",
  WITHDRAW = "탈퇴",
  STOP = "계정 정지",
}
