import { customAxios } from "./CustomAxiosLoader";
import { UpdateUserRequest } from "dto/UserDto";

const me = (token?: string) => {
  if (token) {
    const header = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return customAxios.get(`/me`, header);
  }

  return customAxios.get(`/me`);
};

const userList = (
  searchType: string,
  searchText: string,
  page: number,
  limit: number
) => {
  return customAxios.get(`/users`, {
    params: {
      type: searchType,
      text: searchText,
      limit: limit,
      offset: limit * (page - 1),
    },
  });
};

const user = (userId: number) => {
  return customAxios.get(`/users/${userId}`);
};

const updateUser = (userId: number, req: UpdateUserRequest) => {
  return customAxios.put(`/users/${userId}`, req);
};

export const UserService = {
  me,
  userList,
  user,
  updateUser,
};
