export enum ORDER_LOG_TYPE {
  STATUS = "STATUS", // 상태 변경
  DELIVERY = "DELIVERY", // 배송 변경
  REFUND = "REFUND", // 환불
  SYSTEM = "SYSTEM", // 시스템
}

export enum ORDER_LOG_TYPE_LABELS {
  STATUS = "상태 변경",
  DELIVERY = "배송 변경",
  REFUND = "환불",
  SYSTEM = "시스템",
}
