import {
  Button,
  Group,
  Radio,
  SelectItem,
  Space,
  TextInput,
} from "@mantine/core";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
// import "App.css";
import CustomEditor from "components/CustomEditor";
import { CommonService } from "services/CommonService";
import { CommonResponse } from "dto/CommonDto";
import { RESULT_CODE } from "constants/ResultCode";
import { NoticeDetailDto } from "dto/NoticeDto";
import { USER_ROLE } from "constants/UserRole";
import { Context as AuthContext } from "contexts/AuthContext";
import { NoticeService } from "services/NoticeService";

export default function NoticeDetailPage() {
  const navigate = useNavigate();

  const { noticeId } = useParams();

  const {
    state: { role },
  } = useContext(AuthContext);

  const [currentId, setCurrentId] = useState<number>(-1);

  const [selectItems, setSelectItems] = useState<SelectItem[]>([]);

  const [publish, setPublish] = useState("false");
  const [title, setTitle] = useState<string>("");
  const [content, setContent] = useState<string>("");

  useEffect(() => {
    const id = parseInt(noticeId!);
    setCurrentId(id);

    CommonService.artistsForSelect().then((items) => {
      setSelectItems(items);
    });

    if (id > 0) {
      NoticeService.notice(id).then((response) => {
        const result = response.data as NoticeDetailDto;
        if (result) {
          setPublish("" + result.publish);
          setTitle(result.title);
          setContent(result.content);
        }
      });
    }
  }, []);

  const onClickBack = () => {
    navigate(-1);
  };

  const handleSave = () => {
    if (!title) {
      alert("제목을 입력해야합니다.");
      return;
    }
    if (!content) {
      alert("컨텐츠를 입력해야합니다.");
      return;
    }

    NoticeService.saveNotice({
      id: currentId,
      publish: publish === "true",
      title: title,
      content: content,
    }).then((response) => {
      const result = response.data as CommonResponse;
      if (result.result === RESULT_CODE.SUCCESS) {
        navigate(-1);
      }
    });
  };

  const onClickSave = () => {
    handleSave();
  };

  const onClickModify = () => {
    handleSave();
  };

  const onClickDelete = () => {
    NoticeService.deleteNotice(currentId).then((response) => {
      const result = response.data as CommonResponse;
      if (result.result === RESULT_CODE.SUCCESS) {
        navigate(-1);
      }
    });
  };

  const renderButtons = () => {
    if (currentId > 0) {
      return (
        <Group position="apart">
          <Button onClick={onClickModify}>수정하기</Button>
          {role === USER_ROLE.MASTER ? (
            <Button onClick={onClickDelete}>삭제하기</Button>
          ) : (
            <></>
          )}
        </Group>
      );
    }

    return (
      <Group position="apart">
        <Button onClick={onClickSave}>저장하기</Button>
      </Group>
    );
  };

  return (
    <>
      <Button color="gray" onClick={onClickBack}>
        목록으로
      </Button>
      <Space h="xl" />
      <Space h="xl" />
      <Radio.Group value={publish} onChange={setPublish} label="공개여부">
        <Group mt="xs">
          <Radio value="true" label="공개" />
          <Radio value="false" label="비공개" />
        </Group>
      </Radio.Group>
      <Space h="md" />
      <TextInput
        label="제목"
        placeholder="제목"
        value={title}
        onChange={(event) => setTitle(event.currentTarget.value)}
      />
      <Space h="md" />
      <CustomEditor
        value={content}
        onChange={(value) => {
          setContent(value);
        }}
      />
      <Space h="xl" />
      <Space h="xl" />
      <Space h="xl" />
      {renderButtons()}
    </>
  );
}
