import { customAxios } from "./CustomAxiosLoader";
import { BannerDetailDto } from "dto/BannerDto";

const bannerList = (page: number, limit: number) => {
  return customAxios.get(`/banners`, {
    params: { limit: limit, offset: limit * (page - 1) },
  });
};

const banner = (bannerId: number) => {
  return customAxios.get(`/banners/${bannerId}`);
};

const saveBanner = (req: BannerDetailDto) => {
  return customAxios.post(`/banners`, req);
};

const deleteBanner = (bannerId: number) => {
  return customAxios.delete(`/banners/${bannerId}`);
};

export const BannerService = {
  bannerList,
  saveBanner,
  banner,
  deleteBanner,
};
