import { customAxios } from "./CustomAxiosLoader";
import { ArtistDetailDto } from "dto/ArtistDto";

const artistList = (page: number, limit: number) => {
  return customAxios.get(`/artists`, {
    params: { limit: limit, offset: limit * (page - 1) },
  });
};

const artist = (artistId: number) => {
  return customAxios.get(`/artists/${artistId}`);
};

const saveArtist = (req: ArtistDetailDto) => {
  return customAxios.post(`/artists`, req);
};

const deleteArtist = (artistId: number) => {
  return customAxios.delete(`/artists/${artistId}`);
};

export const ArtistService = {
  artistList,
  saveArtist,
  artist,
  deleteArtist,
};
