import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { Button, Flex, Pagination, Space, Table, Title } from "@mantine/core";
import { Utils } from "utils/Utils";
import { DeliveryService } from "services/DeliveryService";
import { DeliveryAppendDto, DeliveryAppendListResponse } from "dto/DeliveryDto";

const LIST_PAGE_LIMIT = 10;
export default function DeliveryListPage() {
  const navigate = useNavigate();

  const [items, setItems] = useState<DeliveryAppendDto[]>([]);
  const [totalPage, setTotalPage] = useState<number>(1);
  const [currentPage, setCurrentPage] = useState<number>(1);

  useEffect(() => {
    DeliveryService.deliveryList(currentPage, LIST_PAGE_LIMIT).then(
      (response) => {
        const result = response.data as DeliveryAppendListResponse;
        setItems(result.items);
        setTotalPage(Math.ceil(result.total / LIST_PAGE_LIMIT));
        console.log(result);
      }
    );
  }, [currentPage]);

  const onClickCreate = () => {
    navigate("-1");
  };

  const onClickTable = (deliveryId: number) => {
    navigate("" + deliveryId);
  };

  const rows = items.map((item) => (
    <tr key={item.id} onClick={() => onClickTable(item.id)}>
      <td>{item.id}</td>
      <td>{item.title}</td>
      <td>{item.first}</td>
      <td>{item.last}</td>
      <td>{item.price}</td>
    </tr>
  ));

  return (
    <>
      <Title order={1}>추가 배송비</Title>
      <Space h="md" />
      <Flex mih={50} gap="md" justify="flex-end" align="center" wrap="wrap">
        <Button onClick={onClickCreate}>작성하기</Button>
      </Flex>
      <Space h="md" />
      <Table>
        <thead>
          <tr>
            <th>ID</th>
            <th>제목</th>
            <th>처음 우편코드</th>
            <th>마지막 우편코드</th>
            <th>비용</th>
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </Table>
      <Space h="md" />
      <Pagination
        position="center"
        value={currentPage}
        onChange={setCurrentPage}
        total={totalPage}
      />
    </>
  );
}

const Container = styled.div`
  position: relative;
`;
