import {
  Button,
  FileInput,
  Flex,
  Group,
  Image,
  Radio,
  Select,
  SelectItem,
  Space,
  TextInput,
} from "@mantine/core";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
// import "App.css";
import CustomEditor from "components/CustomEditor";
import { CommonService } from "services/CommonService";
import { CommonResponse, UploadImageResponse } from "dto/CommonDto";
import { ContentsService } from "services/ContentsService";
import { RESULT_CODE } from "constants/ResultCode";
import { FeedDetailDto } from "dto/FeedDto";
import { FEED_TYPE } from "constants/FeedType";
import { USER_ROLE } from "constants/UserRole";
import { Context as AuthContext } from "contexts/AuthContext";

export default function FeedDetailPage() {
  const navigate = useNavigate();

  const { feedId } = useParams();

  const {
    state: { role },
  } = useContext(AuthContext);

  const [currentId, setCurrentId] = useState<number>(-1);

  const [selectItems, setSelectItems] = useState<SelectItem[]>([]);

  const [publish, setPublish] = useState("false");
  const [artist, setArtist] = useState<string>("0");
  const [link, setLink] = useState<string>("");
  const [title, setTitle] = useState<string>("");
  const [content, setContent] = useState<string>("");
  const [type, setType] = useState<FEED_TYPE>(FEED_TYPE.BASIC);
  const [thumbnail, setThumbnail] = useState<string>("");
  const [file, setFile] = useState<File>();

  useEffect(() => {
    const id = parseInt(feedId!);
    setCurrentId(id);

    CommonService.artistsForSelect().then((items) => {
      if (id < 0 && items.length > 0) {
        setArtist(items[0].value);
      }

      setSelectItems(items);
    });

    if (id > 0) {
      ContentsService.feed(id).then((response) => {
        const result = response.data as FeedDetailDto;
        if (result) {
          setPublish("" + result.publish);
          setArtist("" + result.artist_id);
          setTitle(result.title);
          setContent(result.content);
          setThumbnail(result.thumbnail);
          setType(result.type);
          setLink(result.link);
        }
        console.log(result);
      });
    }
  }, []);

  useEffect(() => {
    if (file) {
      const formData = new FormData();
      formData.append("file", file);
      CommonService.uploadImage(formData).then((response) => {
        const result = response.data as UploadImageResponse;
        console.log(result);
        setThumbnail(result.url);
      });
    }
    return () => {};
  }, [file]);

  const onClickBack = () => {
    navigate(-1);
  };

  const handleSave = () => {
    if (!title) {
      alert("제목을 입력해야합니다.");
      return;
    }
    if (!content) {
      alert("컨텐츠를 입력해야합니다.");
      return;
    }

    ContentsService.saveFeed({
      id: currentId,
      artist_id: parseInt(artist),
      publish: publish === "true",
      type: type,
      thumbnail: thumbnail,
      title: title,
      content: content,
      link: link,
    }).then((response) => {
      const result = response.data as CommonResponse;
      if (result.result === RESULT_CODE.SUCCESS) {
        navigate(-1);
      }
    });
  };

  const onClickSave = () => {
    handleSave();
  };

  const onClickModify = () => {
    handleSave();
  };

  const onClickDelete = () => {
    ContentsService.deleteFeed(currentId).then((response) => {
      const result = response.data as CommonResponse;
      if (result.result === RESULT_CODE.SUCCESS) {
        navigate(-1);
      }
    });
  };

  const renderButtons = () => {
    if (currentId > 0) {
      return (
        <Group position="apart">
          <Button onClick={onClickModify}>수정하기</Button>
          {role === USER_ROLE.MASTER ? (
            <Button onClick={onClickDelete}>삭제하기</Button>
          ) : (
            <></>
          )}
        </Group>
      );
    }

    return (
      <Group position="apart">
        <Button onClick={onClickSave}>저장하기</Button>
      </Group>
    );
  };

  const renderThumbnail = () => {
    if (!thumbnail) {
      return <></>;
    }

    return (
      <Image
        width={80}
        height={80}
        fit="contain"
        radius="md"
        src={thumbnail}
        alt="썸네일"
      />
    );
  };

  return (
    <>
      <Button color="gray" onClick={onClickBack}>
        목록으로
      </Button>
      <Space h="xl" />
      <Space h="xl" />
      <Select
        label="아티스트"
        placeholder="아티스트를 선택 해주세요."
        data={selectItems}
        value={artist}
        onChange={(event) => setArtist(event!)}
      />
      <Space h="md" />
      <Radio.Group value={publish} onChange={setPublish} label="공개여부">
        <Group mt="xs">
          <Radio value="true" label="공개" />
          <Radio value="false" label="비공개" />
        </Group>
      </Radio.Group>
      <Space h="md" />
      <Radio.Group
        value={type}
        onChange={(value) => setType(value as FEED_TYPE)}
        label="종류"
      >
        <Group mt="xs">
          <Radio value={FEED_TYPE.BASIC} label="기본" />
          <Radio value={FEED_TYPE.YOUTUBE} label="유투브" />
        </Group>
      </Radio.Group>
      <Flex gap="md" align={"center"}>
        {renderThumbnail()}
        <FileInput
          label="썸네일"
          placeholder="파일을 업로드 해주세요."
          value={file}
          onChange={(e) => setFile(e as File)}
        />
      </Flex>
      <TextInput
        label="링크"
        placeholder="링크"
        disabled={type !== FEED_TYPE.YOUTUBE}
        value={link}
        onChange={(event) => setLink(event.currentTarget.value)}
      />
      <TextInput
        label="제목"
        placeholder="제목"
        value={title}
        onChange={(event) => setTitle(event.currentTarget.value)}
      />
      <Space h="md" />
      <CustomEditor
        value={content}
        onChange={(value) => {
          setContent(value);
        }}
      />
      <Space h="xl" />
      <Space h="xl" />
      <Space h="xl" />
      {renderButtons()}
    </>
  );
}
