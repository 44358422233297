import { customAxios } from "./CustomAxiosLoader";
import { DeliveryAppendDto } from "dto/DeliveryDto";

const deliveryList = (page: number, limit: number) => {
  return customAxios.get(`/delivery_appends`, {
    params: { limit: limit, offset: limit * (page - 1) },
  });
};

const delivery = (deliveryId: number) => {
  return customAxios.get(`/delivery_appends/${deliveryId}`);
};

const saveDelivery = (req: DeliveryAppendDto) => {
  return customAxios.post(`/delivery_appends`, req);
};

const deleteDelivery = (deliveryId: number) => {
  return customAxios.delete(`/delivery_appends/${deliveryId}`);
};

export const DeliveryService = {
  deliveryList,
  saveDelivery,
  delivery,
  deleteDelivery,
};
