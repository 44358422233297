import React, { useContext } from "react";
import styled from "styled-components";
import { Route, Routes } from "react-router-dom";
import NavBar from "components/NavBar";
import { AppShell, Button, Flex, Header, Navbar } from "@mantine/core";
import PATH from "Path";
import UserListPage from "./user/UserListPage";
import ProductListPage from "./product/ProductListPage";
import OrderListPage from "./order/OrderListPage";
import ScheduleDetailPage from "./content/ScheduleDetailPage";
import FeedDetailPage from "./content/FeedDetailPage";
import ScheduleListPage from "./content/ScheduleListPage";
import FeedListPage from "./content/FeedListPage";
import ProductDetailPage from "./product/ProductDetailPage";
import { Context as AuthContext } from "contexts/AuthContext";
import UserDetailPage from "./user/UserDetailPage";
import OrderDetailPage from "./order/OrderDetailPage";
import { AuthRoute } from "AuthRoute";
import { USER_ROLE } from "constants/UserRole";
import NoticeListPage from "./notice/NoticeListPage";
import NoticeDetailPage from "./notice/NoticeDetailPage";
import BannerListPage from "./banner/BannerListPage";
import DeliveryListPage from "./delivery/DeliveryListPage";
import BannerDetailPage from "./banner/BannerDetailPage";
import DeliveryDetailPage from "./delivery/DeliveryDetailPage";
import ProductStatsPage from "./product/ProductStatsPage";
import DeliveryInvoicePage from "./delivery/DeliveryInvoicePage";
import CollectionListPage from "./collection/CollectionListPage";
import CollectionDetailPage from "./collection/CollectionDetailPage";
import ArtistListPage from "./artist/ArtistListPage";
import ArtistDetailPage from "./artist/ArtistDetailPage";
import ArtistStatPage from "./artist/ArtistStatsPage";
import AccountListPage from "./account/AccountListPage";
import AccountDetailPage from "./account/AccountDetailPage";

export default function HomePage() {
  const { signOut } = useContext(AuthContext);

  return (
    <Container
      padding="md"
      navbar={
        <Navbar width={{ base: 200 }} height="calc(100% - 60px)" p="xs">
          {<NavBar />}
        </Navbar>
      }
      header={
        <Header height={60} p="xs">
          <Flex
            gap="md"
            justify="flex-end"
            align="center"
            direction="row"
            wrap="wrap"
            h="100%"
          >
            <Button color="red" onClick={() => signOut()}>
              로그아웃
            </Button>
          </Flex>
        </Header>
      }
      styles={(theme) => ({
        main: {
          backgroundColor:
            theme.colorScheme === "dark"
              ? theme.colors.dark[8]
              : theme.colors.gray[0],
        },
      })}
    >
      {
        <Routes>
          <Route
            element={
              <AuthRoute allowedRoles={[USER_ROLE.MASTER, USER_ROLE.ADMIN]} />
            }
          >
            <Route path={PATH.Artists}>
              <Route index element={<ArtistListPage />} />
              <Route path={PATH.ArtistId} element={<ArtistDetailPage />} />
            </Route>
            <Route path={PATH.Products}>
              <Route index element={<ProductListPage />} />
              <Route path={PATH.ProductId} element={<ProductDetailPage />} />
            </Route>
            <Route path={PATH.Notices}>
              <Route index element={<NoticeListPage />} />
              <Route path={PATH.NoticeId} element={<NoticeDetailPage />} />
            </Route>
            <Route path={PATH.Contents + "/" + PATH.Schedule}>
              <Route index element={<ScheduleListPage />} />
              <Route path={PATH.ScheduleId} element={<ScheduleDetailPage />} />
            </Route>
            <Route path={PATH.Contents + "/" + PATH.Feed}>
              <Route index element={<FeedListPage />} />
              <Route path={PATH.FeedId} element={<FeedDetailPage />} />
            </Route>
            <Route path={PATH.Settings + "/" + PATH.Banners}>
              <Route index element={<BannerListPage />} />
              <Route path={PATH.BannerId} element={<BannerDetailPage />} />
            </Route>
            <Route path={PATH.Settings + "/" + PATH.Delivery}>
              <Route index element={<DeliveryListPage />} />
              <Route path={PATH.DeliveryId} element={<DeliveryDetailPage />} />
              <Route path={PATH.Invoices} element={<DeliveryInvoicePage />} />
            </Route>
            <Route
              path={PATH.Settings + "/" + PATH.Invoices}
              element={<DeliveryInvoicePage />}
            />
            <Route path={PATH.Settings + "/" + PATH.Collections}>
              <Route index element={<CollectionListPage />} />
              <Route
                path={PATH.CollectionId}
                element={<CollectionDetailPage />}
              />
            </Route>
            <Route path={PATH.Stats + "/"}>
              <Route path={PATH.Products} index element={<ProductStatsPage />} />
              <Route path={PATH.Artists} index element={<ArtistStatPage />} />
            </Route>
          </Route>
          <Route
            element={
              <AuthRoute
                allowedRoles={[USER_ROLE.MASTER, USER_ROLE.ADMIN, USER_ROLE.CS]}
              />
            }
          >
            <Route path={PATH.Users}>
              <Route index element={<UserListPage />} />
              <Route path={PATH.UserId} element={<UserDetailPage />} />
            </Route>
          </Route>
          <Route
            element={
              <AuthRoute
                allowedRoles={[
                  USER_ROLE.MASTER,
                  USER_ROLE.ADMIN,
                  USER_ROLE.CS,
                  USER_ROLE.LOGISTICS,
                ]}
              />
            }
          >
            <Route path={PATH.Orders}>
              <Route index element={<OrderListPage />} />
              <Route path={PATH.OrderId} element={<OrderDetailPage />} />
            </Route>
          </Route>
          <Route element={<AuthRoute allowedRoles={[USER_ROLE.MASTER]} />} >
            <Route path={PATH.Accounts}>
              <Route index element={<AccountListPage />} />
              <Route path={PATH.AccountId} element={<AccountDetailPage />} />
            </Route>
          </Route>
        </Routes>
      }
    </Container>
  );
}

const Container = styled(AppShell)`
  position: relative;
  width: 100%;
  height: 100%;

  & main {
    padding-top: calc(var(--mantine-header-height, 0px) + 2rem);
    padding-bottom: calc(var(--mantine-footer-height, 0px) + 2rem);
    padding-left: calc(var(--mantine-navbar-width, 0px) + 2rem);
    padding-right: calc(var(--mantine-aside-width, 0px) + 2rem);
  }
`;
