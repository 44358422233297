import { customAxios } from "./CustomAxiosLoader";
import { ORDER_ITEM_STATUS } from "constants/OrderItemStatus";

const products = () => {
  return customAxios.get(`/invoices/products`);
};

const download = (status: ORDER_ITEM_STATUS, ids: number[]) => {
  return customAxios.put(`/invoices`, { status: status, ids: ids });
};

const download_escrow = (ids: number[]) => {
  return customAxios.put(`/invoices/escrow`, ids);
};

const print = (ids: number[]) => {
  return customAxios.put(`/invoices/print`, ids);
};

export const InvoiceService = {
  products,
  download,
  download_escrow,
  print,
};
