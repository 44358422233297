import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  Pagination,
  Space,
  Table,
  Title,
  Card,
  Select,
  Button,
  Flex,
  TextInput,
  Group,
  Radio,
} from "@mantine/core";
import { OrderListItemDto, OrderListResponse } from "dto/OrderDto";
import { OrderService } from "services/OrderService";
import { ORDER_STATUS, ORDER_STATUS_LABELS } from "constants/OrderStatus";

interface State {
  page: number;
  status: string;
  type: string;
  text: string;
}

const searchTypes = [
  { value: "phone", label: "휴대폰" },
  { value: "d_name", label: "주문자" },
  { value: "u_name", label: "회원명" },
  { value: "order", label: "주문번호" },
  { value: "invoice", label: "송장번호" },
];
const LIST_PAGE_LIMIT = 10;
export default function OrderListPage() {
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();

  const [items, setItems] = useState<OrderListItemDto[]>([]);
  const [totalPage, setTotalPage] = useState<number>(1);

  const [searchType, setSearchType] = useState<string>(searchTypes[0].value);
  const [searchText, setSearchText] = useState<string>("");

  const [state, setState] = useState<State>();

  useEffect(() => {
    let temp = state
      ? { ...state }
      : {
          page: 1,
          status: "",
          type: searchTypes[0].value,
          text: "",
        };

    const s = searchParams.get("status");
    if (s !== null) {
      temp.status = s;
    }

    const page = searchParams.get("page");
    if (page !== null) {
      temp.page = parseInt(page);
    }

    const type = searchParams.get("type");
    if (type !== null) {
      temp.type = type;
      setSearchType(type);
    }

    const text = searchParams.get("text");
    if (text !== null) {
      temp.text = text;
      setSearchText(text);
    } else {
      setSearchText("");
    }

    setState(temp);
  }, [searchParams]);

  useEffect(() => {
    if (state) {
      OrderService.orderList(
        state.status,
        state.type,
        state.text,
        state.page,
        LIST_PAGE_LIMIT
      ).then(({ data }) => {
        const result = data as OrderListResponse;
        setItems(result.items);
        setTotalPage(Math.ceil(result.total / LIST_PAGE_LIMIT));
        console.log(data);
      });
    }
  }, [state]);

  const changePage = (page: number) => {
    const params = Object.fromEntries(searchParams.entries());
    setSearchParams({ ...params, page: "" + page });
  };

  const changeStatus = (status: string) => {
    const params = Object.fromEntries(searchParams.entries());
    setSearchParams({ ...params, page: "1", status: status });
  };

  const handleSearch = () => {
    const params = Object.fromEntries(searchParams.entries());
    setSearchParams({
      ...params,
      page: "1",
      type: searchType,
      text: searchText,
    });
  };

  const onClickTable = (orderId: number) => {
    navigate("" + orderId);
  };

  const rows = items.map((item) => (
    <tr key={item.id} onClick={() => onClickTable(item.id)}>
      <td>{item.id}</td>
      <td>{item.uuid}</td>
      <td>{item.user.name}</td>
      <td>{item.delivery.name}</td>
      <td>{item.delivery.phone}</td>
      <td>{item.title}</td>
      <td>{ORDER_STATUS_LABELS[item.status]}</td>
    </tr>
  ));

  return (
    <>
      <Title order={1}>주문</Title>
      <Space h="md" />
      <Card radius="md">
        <Radio.Group
          value={state?.status}
          onChange={(value) => changeStatus(value)}
        >
          <Title order={5}>상태</Title>
          <Group mt="xs">
            <Radio value="" label="전체" />
            <Radio
              value={ORDER_STATUS.PAYMENT_COMPLETE}
              label={ORDER_STATUS_LABELS[ORDER_STATUS.PAYMENT_COMPLETE]}
            />
            <Radio
              value={ORDER_STATUS.DELIVERY_READY}
              label={ORDER_STATUS_LABELS[ORDER_STATUS.DELIVERY_READY]}
            />
            <Radio
              value={ORDER_STATUS.DELIVERY_ING}
              label={ORDER_STATUS_LABELS[ORDER_STATUS.DELIVERY_ING]}
            />
            <Radio
              value={ORDER_STATUS.DELIVERY_COMPLETE}
              label={ORDER_STATUS_LABELS[ORDER_STATUS.DELIVERY_COMPLETE]}
            />
            <Radio
              value={ORDER_STATUS.ORDER_COMPLETE}
              label={ORDER_STATUS_LABELS[ORDER_STATUS.ORDER_COMPLETE]}
            />
            <Radio
              value={ORDER_STATUS.CANCEL_REQUEST}
              label={ORDER_STATUS_LABELS[ORDER_STATUS.CANCEL_REQUEST]}
            />
            <Radio
              value={ORDER_STATUS.CANCEL_COMPLETE}
              label={ORDER_STATUS_LABELS[ORDER_STATUS.CANCEL_COMPLETE]}
            />
          </Group>
        </Radio.Group>
        <Space h="md" />
        <Flex gap="md" align={"center"}>
          <Title order={5}>조건</Title>
          <Select
            value={searchType}
            size="sm"
            withinPortal
            data={searchTypes}
            onChange={(event) => setSearchType(event!)}
          />
          <TextInput
            placeholder="검색"
            value={searchText}
            onChange={(event) => setSearchText(event.currentTarget.value)}
            onKeyUp={(event) => {
              if (event.key === "Enter") {
                handleSearch();
              }
            }}
          />
          <Button onClick={handleSearch}>검색</Button>
        </Flex>
      </Card>
      <Space h="md" />
      <Table>
        <thead>
          <tr>
            <th>ID</th>
            <th>주문번호</th>
            <th>회원명</th>
            <th>주문자</th>
            <th>전화번호</th>
            <th>상품명</th>
            <th>상태</th>
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </Table>
      <Space h="md" />
      <Pagination
        position="center"
        value={state?.page}
        onChange={changePage}
        total={totalPage}
      />
    </>
  );
}
