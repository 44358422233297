import {
  Button,
  Flex,
  Group,
  Select,
  SelectItem,
  Space,
  Text,
  TextInput,
} from "@mantine/core";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { CommonService } from "services/CommonService";
import { UserDetailResponse } from "dto/UserDto";
import { UserService } from "services/UserService";
import { SIGN_TYPE_LABEL } from "constants/SignType";
import { USER_STATUS, USER_STATUS_LABELS } from "constants/UserStatus";
import { Utils } from "utils/Utils";
import { CommonResponse } from "dto/CommonDto";
import { RESULT_CODE } from "constants/ResultCode";

export default function UserDetailPage() {
  const navigate = useNavigate();

  const { userId } = useParams();

  const [currentId, setCurrentId] = useState<number>(-1);

  const [selectItems, setSelectItems] = useState<SelectItem[]>([]);

  const [email, setEmail] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [artist, setArtist] = useState<string>("0");
  const [info, setInfo] = useState<UserDetailResponse>();
  const [status, setStatus] = useState<USER_STATUS>(USER_STATUS.NORMAL);

  useEffect(() => {
    const id = parseInt(userId!);
    setCurrentId(id);

    CommonService.artistsForSelect().then((items) => {
      if (id < 0 && items.length > 0) {
        setArtist(items[0].value);
      }

      setSelectItems(items);
    });

    if (id > 0) {
      UserService.user(id).then(({ data }) => {
        const result = data as UserDetailResponse;
        console.log(data);
        if (result) {
          setInfo(result);
          setEmail(result.email);
          setName(result.name);
          setArtist("" + result.artist);
          setStatus(result.status);
        }
      });
    }
  }, []);

  const onClickBack = () => {
    navigate(-1);
  };

  const handleSave = () => {
    // if (!title) {
    //   alert("제목을 입력해야합니다.");
    //   return;
    // }
    // if (!content) {
    //   alert("컨텐츠를 입력해야합니다.");
    //   return;
    // }
    UserService.updateUser(currentId, {
      name: name,
      email: email,
      artist: parseInt(artist),
      status: status,
    }).then((response) => {
      const result = response.data as CommonResponse;
      if (result.result === RESULT_CODE.SUCCESS) {
        navigate(-1);
      }
    });
  };

  const onClickSave = () => {
    handleSave();
  };

  const onClickModify = () => {
    handleSave();
  };

  // const onClickDelete = () => {
  //   ContentsService.deleteUser(currentId).then((response) => {
  //     const result = response.data as CommonResponse;
  //     if (result.result === RESULT_CODE.SUCCESS) {
  //       navigate(-1);
  //     }
  //   });
  // };

  const renderButtons = () => {
    if (currentId > 0) {
      return (
        <Group position="apart">
          <Button onClick={onClickModify}>수정하기</Button>
          {/*<Button onClick={onClickDelete}>삭제하기</Button>*/}
        </Group>
      );
    }

    return (
      <Group position="apart">
        <Button onClick={onClickSave}>저장하기</Button>
      </Group>
    );
  };

  if (!info) {
    return <></>;
  }

  return (
    <>
      <Button color="gray" onClick={onClickBack}>
        목록으로
      </Button>
      <Space h="xl" />
      <Space h="xl" />
      <TextInput
        label="이메일"
        placeholder="이메일"
        value={email}
        onChange={(event) => setEmail(event.currentTarget.value)}
      />
      <Space h="md" />
      <TextInput
        label="이름"
        placeholder="이름"
        value={name}
        onChange={(event) => setName(event.currentTarget.value)}
      />
      <Space h="md" />
      <Select
        label="아티스트"
        placeholder="아티스트를 선택 해주세요."
        data={selectItems}
        value={artist}
        onChange={(event) => setArtist(event!)}
      />
      <Space h="md" />
      <Select
        label="가입 상태"
        placeholder="가입 상태"
        data={Object.values(USER_STATUS_LABELS)}
        value={USER_STATUS_LABELS[status]}
        onChange={(event) => setArtist(event!)}
      />
      <Space h="md" />
      <Flex gap="md">
        <Text fz="lg">가입 경로 : </Text>
        <Text fz="lg">{SIGN_TYPE_LABEL[info?.type!]}</Text>
      </Flex>
      <Space h="md" />
      <Flex gap="md">
        <Text fz="lg">가입일 : </Text>
        <Text fz="lg">
          {Utils.convertDateToYYMMDDHHmm(
            Utils.convertTimeToDate(info?.created!)
          )}
        </Text>
      </Flex>
      {/*<Radio.Group value={publish} onChange={setPublish} label="공개여부">*/}
      {/*  <Group mt="xs">*/}
      {/*    <Radio value="true" label="공개" />*/}
      {/*    <Radio value="false" label="비공개" />*/}
      {/*  </Group>*/}
      {/*</Radio.Group>*/}
      <Space h="md" />

      <Space h="xl" />
      {renderButtons()}
    </>
  );
}
