import { Box, NavLink } from "@mantine/core";
import React, { useContext, useEffect, useState } from "react";

import {
  IconBoxSeam,
  IconBrandYoutube,
  IconCalendarTime,
  IconClipboardCheck,
  IconGift,
  IconGraph,
  IconLayoutBoard,
  IconMicrophone2,
  IconMountain,
  IconNews,
  IconRectangle,
  IconSettings,
  IconTruckDelivery,
  IconUser,
  IconUserCog,
} from "@tabler/icons-react";
import PATH from "Path";
import { useLocation, useNavigate } from "react-router-dom";
import { Utils } from "utils/Utils";
import { Context as AuthContext } from "contexts/AuthContext";
import { USER_ROLE } from "constants/UserRole";

interface DataType {
  icon: any;
  label: string;
  path: string;
  roles: USER_ROLE[];
  children?: DataType[];
}

const originData: DataType[] = [
  {
    icon: IconMicrophone2,
    label: "아티스트",
    path: PATH.Artists,
    roles: [USER_ROLE.MASTER, USER_ROLE.ADMIN],
  },
  {
    icon: IconUser,
    label: "회원",
    path: PATH.Users,
    roles: [USER_ROLE.MASTER, USER_ROLE.ADMIN, USER_ROLE.CS],
  },
  {
    icon: IconGift,
    label: "상품",
    path: PATH.Products,
    roles: [USER_ROLE.MASTER, USER_ROLE.ADMIN],
  },
  {
    icon: IconClipboardCheck,
    label: "주문",
    path: PATH.Orders,
    roles: [
      USER_ROLE.MASTER,
      USER_ROLE.ADMIN,
      USER_ROLE.CS,
      USER_ROLE.LOGISTICS,
    ],
  },
  {
    icon: IconNews,
    label: "공지사항",
    path: PATH.Notices,
    roles: [USER_ROLE.MASTER, USER_ROLE.ADMIN],
  },
  {
    icon: IconLayoutBoard,
    label: "콘텐츠",
    path: "",
    roles: [USER_ROLE.MASTER, USER_ROLE.ADMIN],
    children: [
      {
        icon: IconCalendarTime,
        label: "스케쥴",
        path: PATH.Contents + "/" + PATH.Schedule,
        roles: [USER_ROLE.MASTER, USER_ROLE.ADMIN],
      },
      {
        icon: IconBrandYoutube,
        label: "피드",
        path: PATH.Contents + "/" + PATH.Feed,
        roles: [USER_ROLE.MASTER, USER_ROLE.ADMIN],
      },
    ],
  },
  {
    icon: IconSettings,
    label: "관리",
    path: "",
    roles: [USER_ROLE.MASTER, USER_ROLE.ADMIN],
    children: [
      {
        icon: IconRectangle,
        label: "배너",
        path: PATH.Settings + "/" + PATH.Banners,
        roles: [USER_ROLE.MASTER, USER_ROLE.ADMIN],
      },
      {
        icon: IconMountain,
        label: "도서산간",
        path: PATH.Settings + "/" + PATH.Delivery,
        roles: [USER_ROLE.MASTER, USER_ROLE.ADMIN],
      },
      {
        icon: IconTruckDelivery,
        label: "배송 송장",
        path: PATH.Settings + "/" + PATH.Invoices,
        roles: [USER_ROLE.MASTER, USER_ROLE.ADMIN],
      },
      {
        icon: IconBoxSeam,
        label: "콜렉션",
        path: PATH.Settings + "/" + PATH.Collections,
        roles: [USER_ROLE.MASTER, USER_ROLE.ADMIN],
      },
    ],
  },
  {
    icon: IconGraph,
    label: "통계",
    path: "",
    roles: [USER_ROLE.MASTER, USER_ROLE.ADMIN],
    children: [
      {
        icon: IconGift,
        label: "상품",
        path: PATH.Stats + "/" + PATH.Products,
        roles: [USER_ROLE.MASTER, USER_ROLE.ADMIN],
      },
      {
        icon: IconMicrophone2,
        label: "아티스트",
        path: PATH.Stats + "/" + PATH.Artists,
        roles: [USER_ROLE.MASTER, USER_ROLE.ADMIN],
      },
    ],
  },
  {
    icon: IconUserCog,
    label: "관리 계정",
    path: PATH.Accounts,
    roles: [USER_ROLE.MASTER],
  },
];

const NavBar = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [currentPath, setCurrentPath] = useState("");
  const [data, setData] = useState<DataType[]>(originData);

  const {
    state: { role },
  } = useContext(AuthContext);

  useEffect(() => {
    let newData: DataType[] = [...originData];
    newData = newData.filter((item) => item.roles.indexOf(role) >= 0);
    setData(newData);

    return () => {};
  }, []);

  useEffect(() => {
    setCurrentPath(location.pathname);
  }, [location.pathname]);

  const onClickNavi = (path: string) => {
    if (!path) {
      return;
    }

    console.log("onClickNavi : " + path);
    navigate(Utils.subPath(path));
  };

  const isActive = (path: string) => {
    if (!path) {
      return false;
    }

    return currentPath.indexOf(path) >= 0;
  };

  const renderItem = (item: DataType) => {
    if (item.children) {
      return (
        <NavLink
          key={item.label}
          active={isActive(item.path)}
          label={item.label}
          defaultOpened
          icon={<item.icon size="1rem" stroke={1.5} />}
          onClick={() => onClickNavi(item.path!)}
        >
          {item.children.map((item) => (
            <NavLink
              key={item.label}
              active={isActive(item.path)}
              label={item.label}
              icon={<item.icon size="1rem" stroke={1.5} />}
              onClick={() => onClickNavi(item.path)}
            />
          ))}
        </NavLink>
      );
    }

    return (
      <NavLink
        key={item.label}
        active={isActive(item.path)}
        label={item.label}
        icon={<item.icon size="1rem" stroke={1.5} />}
        onClick={() => onClickNavi(item.path!)}
      />
    );
  };

  return <Box w={180}>{data.map((item) => renderItem(item))}</Box>;
};

export default NavBar;
