import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  Box,
  Button,
  Flex,
  Group,
  MultiSelect,
  Overlay,
  Radio,
  SimpleGrid,
  Space,
  Stack,
  Text,
  Title,
} from "@mantine/core";
import { InvoiceService } from "services/InvoiceService";
import { UrlResponse } from "dto/CommonDto";
import { RESULT_CODE } from "constants/ResultCode";
import { ORDER_ITEM_STATUS, ORDER_ITEM_STATUS_LABELS } from "../../constants/OrderItemStatus";
import { InvoiceProductDto, ParseExcelDto } from "dto/InvoiceDto";

interface ProductSelectData {
  value: string;
  label: string;
}

export default function DeliveryInvoicePage() {
  const [visible, setVisible] = useState<boolean>(false);
  const [productList, setProductList] = useState<ProductSelectData[]>([]);
  const [values, setValues] = useState<string[]>([]);
  const [status, setStatus] = useState<ORDER_ITEM_STATUS>(ORDER_ITEM_STATUS.ALL);
  const [items, setItems] = useState<ParseExcelDto[]>([]);
  const [total, setTotal] = useState<number>(-1);
  const [current, setCurrent] = useState<number>(-1);

  useEffect(() => {
    InvoiceService.products().then((response) => {
      const result = response.data as InvoiceProductDto[];
      let list: ProductSelectData[] = [];
      for (const item of result) {
        list.push({ value: "" + item.id, label: item.title });
      }
      setProductList(list);
      console.log(result);
    });
  }, []);

  const onClickDownload = () => {
    setVisible(true);
    InvoiceService.download(
      status,
      values.map((value) => parseInt(value))
    ).then(async (response) => {
      const result = response.data as UrlResponse;
      if (result.result === RESULT_CODE.SUCCESS) {
        window.open(result.url);
      }

      setVisible(false);
    });
  };

  const onClickDownloadEscrow = () => {
    InvoiceService.download_escrow(values.map((value) => parseInt(value))).then(async (response) => {
      setVisible(true);
      const result = response.data as UrlResponse;
      if (result.result === RESULT_CODE.SUCCESS) {
        window.open(result.url);
      }

      setVisible(false);
    });
  };


  const onClickPrint = () => {
    InvoiceService.print(values.map((value) => parseInt(value))).then(async (response) => {
      const result = response.data as UrlResponse;
      if (result.result === RESULT_CODE.SUCCESS) {
        window.open(result.url);
      } else {
        alert(result.msg);
      }
    });
  };

  return (
    <Box pos="relative">
      {visible && (
        <Overlay blur={15} center>
          <Title order={3}>업로드 중</Title>
          <Space h="xl" />
          <Flex>
            <Text>(</Text>
            <Text>{current}</Text>
            <Text>/</Text>
            <Text>{total}</Text>
            <Text>)</Text>
          </Flex>
        </Overlay>
      )}

      <Title order={1}>배송 송장</Title>
      <Space h="xl" />
      <Stack>
        <MultiSelect
          data={productList}
          value={values}
          onChange={setValues}
          label="상품 선택"
          placeholder="상품을 선택 해주세요."
          searchable
        />
        {/*<SimpleGrid cols={2}>*/}
          <Button onClick={onClickPrint}>송장 출력</Button>
          {/*<Button onClick={onClickDownloadEscrow}>에스크로 엑셀 다운로드</Button>*/}
        {/*</SimpleGrid>*/}
        <Space h="xl" />
        <Radio.Group
          value={status}
          onChange={(value) => setStatus(value as ORDER_ITEM_STATUS)}
          label="상태"
        >
          <SimpleGrid cols={2}>
            <Group mt="xs">
              <Radio
                value={ORDER_ITEM_STATUS.ALL}
                label={ORDER_ITEM_STATUS_LABELS[ORDER_ITEM_STATUS.ALL]}
              />
              <Radio
                value={ORDER_ITEM_STATUS.PAYMENT_COMPLETE}
                label={ORDER_ITEM_STATUS_LABELS[ORDER_ITEM_STATUS.PAYMENT_COMPLETE]}
              />
              <Radio
                value={ORDER_ITEM_STATUS.DELIVERY_READY}
                label={ORDER_ITEM_STATUS_LABELS[ORDER_ITEM_STATUS.DELIVERY_READY]}
              />
              <Radio
                value={ORDER_ITEM_STATUS.DELIVERY_ING}
                label={ORDER_ITEM_STATUS_LABELS[ORDER_ITEM_STATUS.DELIVERY_ING]}
              />
              <Radio
                value={ORDER_ITEM_STATUS.DELIVERY_COMPLETE}
                label={ORDER_ITEM_STATUS_LABELS[ORDER_ITEM_STATUS.DELIVERY_COMPLETE]}
              />
              <Radio
                value={ORDER_ITEM_STATUS.ORDER_COMPLETE}
                label={ORDER_ITEM_STATUS_LABELS[ORDER_ITEM_STATUS.ORDER_COMPLETE]}
              />
            </Group>
            <Button onClick={onClickDownload}>엑셀 다운로드</Button>
          </SimpleGrid>
        </Radio.Group>
      </Stack>
    </Box>
  );

}

const Container = styled.div`
  position: relative;
`;
