export enum ORDER_ITEM_STATUS {
  ALL = "ALL",  // 전체가
  NORMAL = "NORMAL", // 정상
  CANCEL = "CANCEL", // 취소
  PAYMENT_COMPLETE = "PAYMENT_COMPLETE",  // 결제 완료
  DELIVERY_READY = "DELIVERY_READY", // 배송 준비
  DELIVERY_ING = "DELIVERY_ING", // 배송 중
  DELIVERY_COMPLETE = "DELIVERY_COMPLETE", // 배송 완료
  ORDER_COMPLETE = "ORDER_COMPLETE",  // 주문 완료
}

export enum ORDER_ITEM_STATUS_LABELS {
  ALL = "전체",
  NORMAL = "정상",
  CANCEL = "취소",
  PAYMENT_COMPLETE = "결제 완료",
  DELIVERY_READY = "배송 준비",
  DELIVERY_ING = "배송 중",
  DELIVERY_COMPLETE = "배송 완료",
  ORDER_COMPLETE = "주문 완료",
}
