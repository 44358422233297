import { customAxios } from "./CustomAxiosLoader";
import { RequestArtistStat } from "dto/StatsDto";

const artist = (req: RequestArtistStat) => {
  return customAxios.post(`/stats/artists/`, req);
}

const download = (date: number) => {
  return customAxios.get(`/stats/download/${date}`);
};

const product = (productId: number) => {
  return customAxios.get(`/stats/products/${productId}`);
};


export const StatsService = {
  artist,
  download,
  product,
};
