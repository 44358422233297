import { customAxios } from "./CustomAxiosLoader";
import { ScheduleSaveDto } from "dto/ScheduleDto";
import { FeedDetailDto } from "dto/FeedDto";

const scheduleList = (page: number, limit: number) => {
  return customAxios.get(`/schedule`, {
    params: { limit: limit, offset: limit * (page - 1) },
  });
};

const schedule = (scheduleId: number) => {
  return customAxios.get(`/schedule/${scheduleId}`);
};

const saveSchedule = (req: ScheduleSaveDto) => {
  console.log("saveSchedule");
  console.log(req);
  return customAxios.post(`/schedule`, req);
};

const deleteSchedule = (scheduleId: number) => {
  return customAxios.delete(`/schedule/${scheduleId}`);
};

const feedList = (page: number, limit: number) => {
  return customAxios.get(`/feed`, {
    params: { limit: limit, offset: limit * (page - 1) },
  });
};

const feed = (feedId: number) => {
  return customAxios.get(`/feed/${feedId}`);
};

const saveFeed = (req: FeedDetailDto) => {
  return customAxios.post(`/feed`, req);
};

const deleteFeed = (feedId: number) => {
  return customAxios.delete(`/feed/${feedId}`);
};

export const ContentsService = {
  scheduleList,
  schedule,
  saveSchedule,
  deleteSchedule,
  feedList,
  feed,
  saveFeed,
  deleteFeed,
};
