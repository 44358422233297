import {
  Button,
  Checkbox,
  FileInput,
  Flex,
  Grid,
  Group,
  Image,
  rem,
  Select,
  SelectItem,
  Space,
  Stack,
  TextInput,
} from "@mantine/core";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
// import "App.css";
import { CommonResponse } from "dto/CommonDto";
import { RESULT_CODE } from "constants/ResultCode";
import { CollectionDetailDto, CollectionItemDto } from "dto/CollectionDto";
import { USER_ROLE } from "constants/UserRole";
import { Context as AuthContext } from "contexts/AuthContext";
import { CollectionService } from "services/CollectionService";
import { CommonService } from "services/CommonService";

export default function CollectionDetailPage() {
  const navigate = useNavigate();

  const { collectionId } = useParams();

  const {
    state: { role },
  } = useContext(AuthContext);

  const [selectItems, setSelectItems] = useState<SelectItem[]>([]);

  const [currentId, setCurrentId] = useState<number>(-1);

  const [artist, setArtist] = useState<string>("0");
  const [title, setTitle] = useState<string>("");
  const [subTitle, setSubTitle] = useState<string>("");
  const [mainThumbnail, setMainThumbnail] = useState<string>("");
  const [listThumbnail, setListThumbnail] = useState<string>("");
  const [file0, setFile0] = useState<File>();
  const [file1, setFile1] = useState<File>();
  const [file2, setFile2] = useState<File>();

  const [hiddenContents, setHiddenContents] = useState<boolean>(false);
  const [contents, setContents] = useState<CollectionItemDto[]>([]);

  useEffect(() => {
    const id = parseInt(collectionId!);
    setCurrentId(id);

    CommonService.artistsForSelect().then((items) => {
      if (id < 0 && items.length > 0) {
        setArtist(items[0].value);
      }

      setSelectItems(items);
    });

    if (id > 0) {
      CollectionService.collection(id).then((response) => {
        const result = response.data as CollectionDetailDto;
        if (result) {
          setTitle(result.title);
          setSubTitle(result.sub_title);
          setArtist("" + result.artist_id);
          setListThumbnail(result.list_thumbnail);
          setMainThumbnail(result.main_thumbnail);
          setHiddenContents(result.hidden_contents);
          setContents(result.items);
        }
        console.log(result);
      });
    }
  }, []);

  useEffect(() => {
    if (file0) {
      uploadImage(file0, (url: string) => setMainThumbnail(url));
    }
    return () => {};
  }, [file0]);

  useEffect(() => {
    if (file1) {
      uploadImage(file1, (url: string) => {
        setListThumbnail(url);
      });
    }
    return () => {};
  }, [file1]);

  useEffect(() => {
    if (file2) {
      uploadImage(file2, (url: string) => {
        const newContents = [...contents];
        newContents.push({ id: -1, url: url });
        setContents(newContents);
      });
    }
    return () => {};
  }, [file2]);

  const uploadImage = async (file: File, onComplete: (url: string) => void) => {
    const formData = new FormData();
    formData.append("file", file);
    const result = await CommonService.uploadImage(formData);
    console.log(result);
    onComplete(result.data.url);
  };

  const onClickBack = () => {
    navigate(-1);
  };

  const handleSave = () => {
    if (!title) {
      return;
    }

    CollectionService.saveCollection({
      id: currentId,
      title: title,
      sub_title: subTitle,
      artist_id: parseInt(artist),
      hidden_contents: hiddenContents,
      list_thumbnail: listThumbnail,
      main_thumbnail: mainThumbnail,
      items: contents,
    }).then((response) => {
      const result = response.data as CommonResponse;
      if (result.result === RESULT_CODE.SUCCESS) {
        navigate(-1);
      }
    });
  };

  const onClickSave = () => {
    handleSave();
  };

  const onClickModify = () => {
    handleSave();
  };

  const onClickDelete = () => {
    CollectionService.deleteCollection(currentId).then((response) => {
      const result = response.data as CommonResponse;
      if (result.result === RESULT_CODE.SUCCESS) {
        navigate(-1);
      }
    });
  };

  const deleteContent = (index: number) => {
    const newContents = [...contents];
    newContents.splice(index, 1);
    setContents(newContents);
  };

  const forwardContent = (index: number) => {
    if (index === 0) {
      return;
    }

    const newContents = [...contents];
    const temp = newContents[index];
    newContents[index] = newContents[index - 1];
    newContents[index - 1] = temp;
    setContents(newContents);
  };

  const backwardContent = (index: number) => {
    if (index === contents.length - 1) {
      return;
    }

    const newContents = [...contents];
    const temp = newContents[index];
    newContents[index] = newContents[index + 1];
    newContents[index + 1] = temp;
    setContents(newContents);
  };

  const renderButtons = () => {
    if (currentId > 0) {
      return (
        <Group position="apart">
          <Button onClick={onClickModify}>수정하기</Button>
          {role === USER_ROLE.MASTER ? (
            <Button onClick={onClickDelete}>삭제하기</Button>
          ) : (
            <></>
          )}
        </Group>
      );
    }

    return (
      <Group position="apart">
        <Button onClick={onClickSave}>저장하기</Button>
      </Group>
    );
  };

  const renderImage = (url?: string) => {
    if (!url) {
      return <></>;
    }

    return (
      <Image width={180} height={180} fit="contain" radius="md" src={url} />
    );
  };

  return (
    <>
      <Button color="gray" onClick={onClickBack}>
        목록으로
      </Button>
      <Space h="xl" />
      <Select
        label="아티스트"
        placeholder="아티스트를 선택 해주세요."
        data={selectItems}
        value={artist}
        onChange={(event) => setArtist(event!)}
      />
      <Space h="md" />
      <TextInput
        label="제목"
        placeholder="제목"
        value={title}
        onChange={(event) => setTitle(event.currentTarget.value)}
      />
      <Space h="xl" />
      <TextInput
        label="부 제목"
        placeholder="부 제목"
        value={subTitle}
        onChange={(event) => setSubTitle(event.currentTarget.value)}
      />
      <Space h="xl" />
      <Flex gap="md" align={"center"}>
        {renderImage(mainThumbnail)}
        <FileInput
          label="등록 썸네일"
          placeholder="파일을 업로드 해주세요."
          value={file0}
          onChange={(e) => setFile0(e as File)}
        />
      </Flex>
      <Space h="md" />
      <Flex gap="md" align={"center"}>
        {renderImage(listThumbnail)}
        <FileInput
          label="콜렉션 리스트 썸네일"
          placeholder="파일을 업로드 해주세요."
          value={file1}
          onChange={(e) => setFile1(e as File)}
        />
      </Flex>
      <Space h="md" />
      <Checkbox
        label="히든 컨텐츠"
        checked={hiddenContents}
        onChange={(e) => {
          setHiddenContents(e.target.checked);
        }}
      />
      <Space h="md" />
      {hiddenContents ? (
        <Stack>
          <FileInput
            label="히든 컨텐츠 이미지"
            placeholder="파일을 업로드 해주세요."
            value={file2}
            onChange={(e) => setFile2(e as File)}
          />
          <Grid>
            {contents.map((content, index) => (
              <Grid.Col span={4} key={content.url}>
                <Flex>
                  <Image
                    width={180}
                    height={180}
                    fit="contain"
                    radius="md"
                    src={content.url}
                  />
                  <Stack
                    justify="space-around"
                    sx={(theme) => ({
                      backgroundColor:
                        theme.colorScheme === "dark"
                          ? theme.colors.dark[8]
                          : theme.colors.gray[0],
                    })}
                  >
                    <Button onClick={() => deleteContent(index)}>삭제</Button>
                    <Flex direction="column">
                      <Button onClick={() => forwardContent(index)}>←</Button>
                      <Space h="xs" />
                      <Button onClick={() => backwardContent(index)}>→</Button>
                    </Flex>
                  </Stack>
                </Flex>
              </Grid.Col>
            ))}
          </Grid>
        </Stack>
      ) : (
        <></>
      )}
      <Space h="xl" />
      {renderButtons()}
    </>
  );
}
