import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  Button,
  Card,
  Flex,
  Pagination,
  Space,
  Table,
  TextInput,
  Title,
} from "@mantine/core";
import { AccountItemDto, AccountListResponse } from "dto/AccountDto";
import { AccountService } from "services/AccountService";
import { USER_STATUS_LABELS } from "constants/UserStatus";
import { USER_ROLE_LABELS} from "../../constants/UserRole";

const LIST_PAGE_LIMIT = 10;
export default function AccountListPage() {
  const navigate = useNavigate();
  const [items, setItems] = useState<AccountItemDto[]>([]);
  const [totalPage, setTotalPage] = useState<number>(1);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [value, setValue] = useState<string>("");

  useEffect(() => {
    AccountService.accountList(currentPage, LIST_PAGE_LIMIT).then((response) => {
      const result = response.data as AccountListResponse;
      setItems(result.items);
      setTotalPage(Math.ceil(result.total / LIST_PAGE_LIMIT));
      console.log(result);
    });
  }, [currentPage]);

  const handleSearch = (value: string) => {
    if (value) {
      AccountService.isExistAccount(value).then((response) => {
        const accountId = response.data as number;
        if (accountId) {
          navigate("" + accountId);
        } else {
          alert("이메일이 정확하지 않습니다.");
        }
      });
    } else {
      alert("값을 입력하세요.");
    }
  };

  const onClickTable = (accountId: number) => {
    navigate("" + accountId);
  };

  const rows = items.map((item) => (
    <tr key={item.id} onClick={() => onClickTable(item.id)}>
      <td>{item.id}</td>
      <td>{item.name}</td>
      <td>{item.email}</td>
      <td>{USER_ROLE_LABELS[item.role]}</td>
      <td>{USER_STATUS_LABELS[item.status]}</td>
    </tr>
  ));

  return (
    <>
      <Title order={1}>관리 계정</Title>
      <Space h="calc(1rem + 50px)" />
      <Card radius="md">
        <Title order={5}>관리 계정 추가하기</Title>
        <Space h="md" />
        <Flex gap="md" align={"center"}>
          <TextInput
            placeholder="이메일 입력"
            value={value}
            onChange={(event) => setValue(event.currentTarget.value)}
            onKeyUp={(event) => {
              if (event.key === "Enter") {
                handleSearch(value);
              }
            }}
          />
          <Button onClick={() => handleSearch(value)}>검색</Button>
        </Flex>
      </Card>
      <Space h="md" />
      <Table>
        <thead>
          <tr>
            <th>ID</th>
            <th>이름</th>
            <th>이메일</th>
            <th>권한</th>
            <th>활성여부</th>
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </Table>
      <Space h="md" />
      <Pagination
        position="center"
        value={currentPage}
        onChange={setCurrentPage}
        total={totalPage}
      />
    </>
  );
}
