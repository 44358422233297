import {
  Button,
  Checkbox,
  Flex,
  Group,
  MultiSelect,
  Select,
  Radio,
  Space,
  TextInput,
} from "@mantine/core";
import { DateTimePicker, DateValue } from "@mantine/dates";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
// import "App.css";
import { CommonService } from "services/CommonService";
import { CommonResponse } from "dto/CommonDto";
import { ContentsService } from "services/ContentsService";
import { RESULT_CODE } from "constants/ResultCode";
import { ScheduleDetailDto } from "dto/ScheduleDto";
import { SCHEDULE_TYPE, SCHEDULE_TYPE_LABELS } from "constants/ScheduleType";
import { Utils } from "utils/Utils";
import { Context as AuthContext } from "contexts/AuthContext";
import { USER_ROLE } from "constants/UserRole";

interface ArtistSelectData {
  value: string;
  label: string;
}

export default function ScheduleDetailPage() {
  const navigate = useNavigate();

  const { scheduleId } = useParams();

  const {
    state: { role },
  } = useContext(AuthContext);

  const [currentId, setCurrentId] = useState<number>(-1);
  const [publish, setPublish] = useState("false");
  const [artistList, setArtistList] = useState<ArtistSelectData[]>([]);
  const [values, setValues] = useState<string[]>([]);
  const [title, setTitle] = useState<string>("");
  const [type, setType] = useState<SCHEDULE_TYPE>(SCHEDULE_TYPE.CONCERT);
  const [allDay, setAllDay] = useState(false);
  const [time, setTime] = useState<Date>(new Date());

  useEffect(() => {
    const id = parseInt(scheduleId!);
    setCurrentId(id);

    CommonService.artistsForSelect().then((items) => {
      let list: ArtistSelectData[] = []
      for (const item of items) {
        list.push({value: "" + item.value, label: item.label!});
      }
      setArtistList(list);
    });

    if (id > 0) {
      ContentsService.schedule(id).then((response) => {
        const result = response.data as ScheduleDetailDto;
        console.log(result);
        if (result) {
          setPublish("" + result.publish);
          setValues(["" + result.artist_id]);
          setTitle(result.title);
          setType(result.type);
          setAllDay(result.all_day);
          setTime(Utils.convertTimeToDate(result.time));
        }
      });
    }
  }, []);

  const onClickBack = () => {
    navigate(-1);
  };

  const handleSave = () => {
    if (!title) {
      alert("제목을 입력해야합니다.");
      return;
    }

    ContentsService.saveSchedule({
      id: currentId,
      artist_id: values.map((value) => parseInt(value)),
      type: type,
      publish: publish === "true",
      title: title,
      all_day: allDay,
      time: Utils.convertDateToTime(time),
    }).then((response) => {
      const result = response.data as CommonResponse;
      if (result.result === RESULT_CODE.SUCCESS) {
        navigate(-1);
      }
    });
  };

  const onClickSave = () => {
    handleSave();
  };

  const onClickModify = () => {
    handleSave();
  };

  const onClickDelete = () => {
    ContentsService.deleteSchedule(currentId).then((response) => {
      const result = response.data as CommonResponse;
      if (result.result === RESULT_CODE.SUCCESS) {
        navigate(-1);
      }
    });
  };

  const onChangeStartDate = (input: DateValue) => {
    if (input) {
      setTime(input as Date);
    }
  };

  const renderButtons = () => {
    if (currentId > 0) {
      return (
        <Group position="apart">
          <Button onClick={onClickModify}>수정하기</Button>
          {role === USER_ROLE.MASTER ? (
            <Button onClick={onClickDelete}>삭제하기</Button>
          ) : (
            <></>
          )}
        </Group>
      );
    }
    return (
      <Group position="apart">
        <Button onClick={onClickSave}>저장하기</Button>
      </Group>
    );
  };

  const renderSelect = () => {
    if (currentId > 0) {
      return (
        <Select
          label="아티스트"
          placeholder="아티스트를 선택 해주세요."
          data={artistList}
          value={values.toString()}
          onChange={(event) => setValues([event!])}
        />
      );
    }
    return(
        <MultiSelect
            data={artistList}
            value={values}
            onChange={setValues}
            label="아티스트"
            placeholder="아티스트를 선택 해주세요."
            searchable
        />
    );
  };

  return (
    <>
      <Button color="gray" onClick={onClickBack}>
        목록으로
      </Button>
      <Space h="xl" />
      <Space h="xl" />
      {renderSelect()}
      <Space h="xs" />
      <Radio.Group value={publish} onChange={setPublish} label="공개여부">
        <Group mt="xs">
          <Radio value="true" label="공개" />
          <Radio value="false" label="비공개" />
        </Group>
      </Radio.Group>
      <Space h="xs" />
      <Radio.Group
        value={type}
        onChange={(value) => setType(value as SCHEDULE_TYPE)}
        label="종류"
      >
        <Group mt="xs">
          <Radio
            value={SCHEDULE_TYPE.CONCERT}
            label={SCHEDULE_TYPE_LABELS.CONCERT}
          />
          <Radio
            value={SCHEDULE_TYPE.BIRTHDAY}
            label={SCHEDULE_TYPE_LABELS.BIRTHDAY}
          />
          <Radio value={SCHEDULE_TYPE.LIVE} label={SCHEDULE_TYPE_LABELS.LIVE} />
          <Radio
            value={SCHEDULE_TYPE.FESTIVAL}
            label={SCHEDULE_TYPE_LABELS.FESTIVAL}
          />
          <Radio
            value={SCHEDULE_TYPE.RADIO}
            label={SCHEDULE_TYPE_LABELS.RADIO}
          />
          <Radio value={SCHEDULE_TYPE.ETC} label={SCHEDULE_TYPE_LABELS.ETC} />
        </Group>
      </Radio.Group>
      <Space h="xs" />
      <Flex gap="md" align={"center"}>
        <DateTimePicker
          label="시간"
          placeholder="시간"
          valueFormat="YYYY년 MM월 DD일 HH시 mm분"
          maw={400}
          value={time}
          locale={"ko"}
          onChange={(e) => onChangeStartDate(e)}
        />
        <Checkbox
          label="하루종일"
          checked={allDay}
          onChange={(e) => setAllDay(e.target.checked)}
        />
      </Flex>
      <Space h="md" />
      <TextInput
        label="제목"
        placeholder="제목"
        value={title}
        onChange={(event) => setTitle(event.currentTarget.value)}
      />
      <Space h="md" />
      <Space h="xl" />
      {renderButtons()}
    </>
  );
}
