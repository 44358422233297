import { MantineProvider } from "@mantine/core";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import SignInPage from "pages/auth/SignInPage";
import HomePage from "pages/HomePage";
import PATH from "Path";
import CustomAxiosLoader from "services/CustomAxiosLoader";
import { ProtectedRoute } from "./ProtectedRoute";
import GlobalStyle from "styles/common";

function App() {
  return (
    <BrowserRouter>
      <CustomAxiosLoader />
      <GlobalStyle />
      <MantineProvider withGlobalStyles withNormalizeCSS>
        <Routes>
          <Route path={PATH.Home}>
            <Route index element={<SignInPage.SignIn />} />
            <Route path="kakao" element={<SignInPage.Redirects.Kakao />} />
            <Route path="naver" element={<SignInPage.Redirects.Naver />} />
            <Route path="google" element={<SignInPage.Redirects.Google />} />
          </Route>
          <Route element={<ProtectedRoute />}>
            <Route path={PATH.Admin + "/*"} element={<HomePage />} />
          </Route>
          <Route path={PATH.Error} element={<div>Not Found!</div>} />
        </Routes>
      </MantineProvider>
    </BrowserRouter>
  );
}

export default App;
