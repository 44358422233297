import React, {useEffect, useState} from "react";
import { StatsService } from "services/StatsService";
import { ArtistStatDto } from "dto/StatsDto";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";
import {Radio, Group, Button, Grid, Select, Title, Box, Flex} from "@mantine/core";
import { DatePickerInput } from "@mantine/dates";
import { CommonService } from "services/CommonService";

interface ArtistSelectData {
  value: string;
  label: string;
}

interface RequestArtistStat {
  artist_id: number;
  start_date: string|undefined;
  end_date: string|undefined;
  period: string;
}

interface GraphData {
  name: string;
  value: number;
}

export default function ProductStatsPage() {
  const [artist, setArtist] = useState<string>("0");
  const [date, setDate] = useState<[Date | null, Date | null]>([null, null]);
  const [period, setPeriod] = useState<string>("");
  const [total, setTotal] = useState<number>(0);
  const [artistList, setArtistList] = useState<ArtistSelectData[]>([]);
  const [graphData, setGraphData] = useState<GraphData[]>([]);

  useEffect(() => {
    CommonService.artistsForSelect().then((items) => {
      let list: ArtistSelectData[] = []
      for (const item of items) {
        list.push({value: "" + item.value, label: item.label!});
      }
      setArtistList(list);
    });
  }, []);

  const getGraphData = () => {
    if (parseInt(artist) < 1) {
      alert('아티스트를 선택하세요');
      return
    }

    const req: RequestArtistStat = {
      artist_id: parseInt(artist),
      start_date: date[0]?.toDateString(),
      end_date: date[1]?.toDateString(),
      period: period,
    };

    StatsService.artist(req).then((response) => {
      const result = response.data as ArtistStatDto[];
      let data: GraphData[] = [];
      let count = 0;

      for (const dto of result) {
        data.push({
          name: dto.date,
          value: dto.total,
        });
        count += dto.total
      }

      setGraphData(data);
      setTotal(count)
      console.log(result);
    });
  };

  return (
    <>
      <Grid align="center">
        <Grid.Col span={3}>
          <Select
            label="아티스트"
            placeholder="아티스트를 선택 해주세요."
            data={artistList}
            onChange={(event) => setArtist(event!)}
          />
        </Grid.Col>
        <Grid.Col span={4}>
          <DatePickerInput
            clearable
            type="range"
            label="기간"
            placeholder="기간 설정"
            valueFormat="YYYY년 MM월 DD일"
            value={date}
            onChange={setDate}
          />
        </Grid.Col>
        <Grid.Col span={3}>
          <Radio.Group label="기준" onChange={(value) => setPeriod(value)}>
            <Group mt="xs">
              <Radio value="monthly" label="월별" />
              <Radio value="weekly" label="주별" />
              <Radio value="daily" label="일별" />
            </Group>
          </Radio.Group>
        </Grid.Col>
        <Grid.Col span={1}>
          <Button onClick={getGraphData}>검색</Button>
        </Grid.Col>
      </Grid>
      <Flex gap={"md"} align={"center"}>
        <Box mt="xl"><Title order={3}>총 가입자 : {total}명</Title></Box>
      </Flex>
      <BarChart
        width={1000}
        height={700}
        data={graphData}
        margin={{
          top: 50,
          right: 30,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar dataKey="value" fill="#8884d8" />
      </BarChart>
    </>
  );
}
