export enum SIGN_TYPE {
  KAKAO = "KAKAO",
  NAVER = "NAVER",
  GOOGLE = "GOOGLE",
}

export enum SIGN_TYPE_LABEL {
  KAKAO = "카카오",
  NAVER = "네이버",
  GOOGLE = "구글",
}
