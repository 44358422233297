import { customAxios } from "./CustomAxiosLoader";
import { ModifyOrderRequest, RefundOrderRequest } from "dto/OrderDto";

const orderList = (
  status: string,
  searchType: string,
  searchText: string,
  page: number,
  limit: number
) => {
  return customAxios.get(`/order`, {
    params: {
      status: status,
      type: searchType,
      text: searchText,
      limit: limit,
      offset: limit * (page - 1),
    },
  });
};

const order = (orderId: number) => {
  return customAxios.get(`/order/${orderId}`);
};

const modifyOrder = (orderId: number, req: ModifyOrderRequest) => {
  return customAxios.put(`/order/${orderId}`, req);
};

const refund = (orderId: number, req: RefundOrderRequest) => {
  return customAxios.post(`/order/${orderId}/refund`, req);
};

export const OrderService = {
  orderList,
  order,
  modifyOrder,
  refund,
};
