import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { Button, Flex, Pagination, Space, Table, Title } from "@mantine/core";
import { Utils } from "utils/Utils";
import { NoticeService } from "services/NoticeService";
import { NoticeListResponse, NoticeSimpleDto } from "dto/NoticeDto";

const LIST_PAGE_LIMIT = 10;
export default function NoticeListPage() {
  const navigate = useNavigate();

  const [items, setItems] = useState<NoticeSimpleDto[]>([]);
  const [totalPage, setTotalPage] = useState<number>(1);
  const [currentPage, setCurrentPage] = useState<number>(1);

  useEffect(() => {
    NoticeService.noticeList(currentPage, LIST_PAGE_LIMIT).then((response) => {
      const result = response.data as NoticeListResponse;
      setItems(result.items);
      setTotalPage(Math.ceil(result.total / LIST_PAGE_LIMIT));
      console.log(result);
    });
  }, [currentPage]);

  const onClickCreate = () => {
    navigate("-1");
  };

  const onClickTable = (noticeId: number) => {
    navigate("" + noticeId);
  };

  const rows = items.map((item) => (
    <tr key={item.id} onClick={() => onClickTable(item.id)}>
      <td>{item.id}</td>
      <td>{item.title}</td>
      <td>
        {Utils.convertDateToYYMMDD(Utils.convertTimeToDate(item.created))}
      </td>
      <td>{item.publish ? "공개" : "비공개"}</td>
    </tr>
  ));

  return (
    <>
      <Title order={1}>공지사항</Title>
      <Space h="md" />
      <Flex mih={50} gap="md" justify="flex-end" align="center" wrap="wrap">
        <Button onClick={onClickCreate}>작성하기</Button>
      </Flex>
      <Space h="md" />
      <Table>
        <thead>
          <tr>
            <th>ID</th>
            <th>제목</th>
            <th>등록일</th>
            <th>사용</th>
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </Table>
      <Space h="md" />
      <Pagination
        position="center"
        value={currentPage}
        onChange={setCurrentPage}
        total={totalPage}
      />
    </>
  );
}

const Container = styled.div`
  position: relative;
`;
