const PATH = {
  Home: "/",

  Admin: "/admin",

  Artists: "artists",
  ArtistId: ":artistId",

  Users: "users",
  UserId: ":userId",

  Stats: "stats",

  Products: "products",
  ProductId: ":productId",

  Orders: "orders",
  OrderId: ":orderId",

  Contents: "contents",

  Notices: "notices",
  NoticeId: ":noticeId",

  Schedule: "schedule",
  ScheduleId: ":scheduleId",
  Feed: "feed",
  FeedId: ":feedId",

  Settings: "settings",
  Banners: "banners",
  BannerId: ":bannerId",
  Delivery: "delivery",
  DeliveryId: ":deliveryId",

  Invoices: "invoices",

  Collections: "collections",
  CollectionId: ":collectionId",

  Accounts: "accounts",
  AccountId: ":accountId",

  Error: "*",
};
export default PATH;
