import { customAxios } from "./CustomAxiosLoader";
import { ProductDetailDto } from "dto/ProductDto";

const productList = (page: number, limit: number) => {
  return customAxios.get(`/product`, {
    params: { limit: limit, offset: limit * (page - 1) },
  });
};

const product = (productId: number) => {
  return customAxios.get(`/product/${productId}`);
};

const saveProduct = (req: ProductDetailDto) => {
  return customAxios.post(`/product`, req);
};

const deleteProduct = (productId: number) => {
  return customAxios.delete(`/product/${productId}`);
};

export const ProductsService = {
  productList,
  saveProduct,
  product,
  deleteProduct,
};
