import {
  Button,
  ColorPicker,
  FileInput,
  Flex,
  Group,
  Image,
  Radio,
  Space,
  Table,
  TextInput,
} from "@mantine/core";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
// import "App.css";
import { CommonResponse } from "dto/CommonDto";
import { RESULT_CODE } from "constants/ResultCode";
import { ArtistCafeDto, ArtistDetailDto } from "dto/ArtistDto";
import { USER_ROLE } from "constants/UserRole";
import { Context as AuthContext } from "contexts/AuthContext";
import { ArtistService } from "services/ArtistService";
import { CommonService } from "services/CommonService";

export default function ArtistDetailPage() {
  const navigate = useNavigate();

  const { artistId } = useParams();

  const {
    state: { role },
  } = useContext(AuthContext);

  const [currentId, setCurrentId] = useState<number>(-1);

  const [publish, setPublish] = useState("false");
  const [name, setName] = useState<string>("");
  const [eng, setEng] = useState<string>("");
  const [key, setKey] = useState<string>("");
  const [thumbnail, setThumbnail] = useState<string>("");
  const [photo, setPhoto] = useState<string>("");
  const [color, setColor] = useState<string>("ffffff");
  const [isWhite, setIsWhite] = useState("false");
  const [file0, setFile0] = useState<File>();
  const [file1, setFile1] = useState<File>();

  const [cafes, setCafes] = useState<ArtistCafeDto[]>([]);

  useEffect(() => {
    const id = parseInt(artistId!);
    setCurrentId(id);

    if (id > 0) {
      ArtistService.artist(id).then((response) => {
        const result = response.data as ArtistDetailDto;
        if (result) {
          setName(result.name);
          setEng(result.eng);
          setKey(result.key);
          setThumbnail(result.thumbnail);
          setPhoto(result.photo);
          setColor(result.color);
          setIsWhite("" + result.is_white);
          setPublish("" + result.publish);
          setCafes(result.cafes);
        }
        console.log(result);
      });
    }
  }, []);

  useEffect(() => {
    if (file0) {
      uploadImage(file0, (url: string) => setThumbnail(url));
    }
    return () => {};
  }, [file0]);

  useEffect(() => {
    if (file1) {
      uploadImage(file1, (url: string) => {
        setPhoto(url);
      });
    }
    return () => {};
  }, [file1]);

  const uploadImage = async (file: File, onComplete: (url: string) => void) => {
    const formData = new FormData();
    formData.append("file", file);
    const result = await CommonService.uploadImage(formData);
    console.log(result);
    onComplete(result.data.url);
  };

  const onClickBack = () => {
    navigate(-1);
  };

  const handleSave = () => {
    ArtistService.saveArtist({
      id: currentId,
      name: name,
      eng: eng,
      key: key,
      thumbnail: thumbnail,
      photo: photo,
      color: color,
      is_white: isWhite === "true",
      cafes: cafes,
      publish: publish === "true",
    }).then((response) => {
      const result = response.data as CommonResponse;
      if (result.result === RESULT_CODE.SUCCESS) {
        navigate(-1);
      }
    });
  };

  const onClickSave = () => {
    handleSave();
  };

  const onClickModify = () => {
    handleSave();
  };

  const onClickDelete = () => {
    ArtistService.deleteArtist(currentId).then((response) => {
      const result = response.data as CommonResponse;
      if (result.result === RESULT_CODE.SUCCESS) {
        navigate(-1);
      }
    });
  };

  const renderButtons = () => {
    if (currentId > 0) {
      return (
        <Group position="apart">
          <Button onClick={onClickModify}>수정하기</Button>
          {role === USER_ROLE.MASTER ? (
            <Button onClick={onClickDelete}>삭제하기</Button>
          ) : (
            <></>
          )}
        </Group>
      );
    }

    return (
      <Group position="apart">
        <Button onClick={onClickSave}>저장하기</Button>
      </Group>
    );
  };

  const renderImage = (url?: string) => {
    if (!url) {
      return <></>;
    }

    return (
      <Image width={180} height={180} fit="contain" radius="md" src={url} />
    );
  };

  const rows = cafes.map((item, idx) => (
    <tr key={idx}>
      <td>{item.id}</td>
      <td>
        <TextInput
          placeholder="이름"
          value={item.title}
          onChange={(event) => {
            const newCafes = [...cafes];
            newCafes[idx].title = event.currentTarget.value;
            setCafes(newCafes);
          }}
        />
      </td>
      <td>
        <TextInput
          placeholder="URL"
          value={item.url}
          onChange={(event) => {
            const newCafes = [...cafes];
            newCafes[idx].url = event.currentTarget.value;
            setCafes(newCafes);
          }}
        />
      </td>
      <td>
        <Button
          onClick={() => {
            const newCafes = [...cafes];
            newCafes.splice(idx, 1);
            setCafes(newCafes);
          }}
        >
          삭제
        </Button>
      </td>
    </tr>
  ));

  return (
    <>
      <Button color="gray" onClick={onClickBack}>
        목록으로
      </Button>
      <Space h="xl" />
      <Radio.Group value={publish} onChange={setPublish} label="공개여부">
        <Group mt="xs">
          <Radio value="true" label="공개" />
          <Radio value="false" label="비공개" />
        </Group>
      </Radio.Group>
      <Space h="xl" />
      <TextInput
        label="이름"
        placeholder="이름"
        value={name}
        onChange={(event) => setName(event.currentTarget.value)}
      />
      <Space h="xl" />
      <TextInput
        label="영어 이름"
        placeholder="영어 이름"
        value={eng}
        onChange={(event) => setEng(event.currentTarget.value)}
      />
      <Space h="xl" />
      <TextInput
        label="이니셜"
        placeholder="이니셜"
        value={key}
        onChange={(event) => setKey(event.currentTarget.value)}
      />
      <Space h="xl" />
      <Flex gap="md" align={"center"}>
        {renderImage(thumbnail)}
        <FileInput
          label="루트 썸네일"
          placeholder="파일을 업로드 해주세요."
          value={file0}
          onChange={(e) => setFile0(e as File)}
        />
      </Flex>
      <Space h="md" />
      <Flex gap="md" align={"center"}>
        {renderImage(photo)}
        <FileInput
          label="아티스트 홈 포토"
          placeholder="파일을 업로드 해주세요."
          value={file1}
          onChange={(e) => setFile1(e as File)}
        />
      </Flex>
      <Space h="md" />
      <Flex gap="md" align={"center"}>
        <ColorPicker format="hex" value={"#" + color} />
        <TextInput
          label="컬러"
          placeholder="FFFFFF"
          value={color}
          onChange={(event) => setColor(event.currentTarget.value)}
        />
      </Flex>
      <Space h="md" />
      <Radio.Group value={isWhite} onChange={setIsWhite} label="테마색상">
        <Group mt="xs">
          <Radio value="true" label="화이트" />
          <Radio value="false" label="다크" />
        </Group>
      </Radio.Group>
      <Space h="md" />
      <Button
        onClick={() => {
          const newCafes = [...cafes];
          newCafes.push({ id: -1, url: "", title: "" });
          setCafes(newCafes);
        }}
      >
        추가
      </Button>
      <Table>
        <thead>
          <tr>
            <th>ID</th>
            <th>이름</th>
            <th>URL</th>
            <th>삭제</th>
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </Table>
      <Space h="xl" />
      {renderButtons()}
    </>
  );
}
