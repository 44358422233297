export enum USER_ROLE {
  USER = "USER",
  CS = "CS",
  LOGISTICS = "LOGISTICS",
  ADMIN = "ADMIN",
  MASTER = "MASTER",
}

export enum USER_ROLE_LABELS {
  USER = "사용자",
  CS = "고객지원",
  LOGISTICS = "물류",
  ADMIN = "관리자",
  MASTER = "마스터",
}
