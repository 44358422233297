import {
  Button,
  Group,
  Radio,
  Space,
  TextInput,
} from "@mantine/core";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AccountDetailResponse } from "dto/AccountDto";
import { AccountService } from "services/AccountService";
import { USER_STATUS, USER_STATUS_LABELS } from "constants/UserStatus";
import { USER_ROLE, USER_ROLE_LABELS } from "../../constants/UserRole";
import { CommonResponse } from "dto/CommonDto";
import { RESULT_CODE } from "constants/ResultCode";

export default function AccountDetailPage() {
  const navigate = useNavigate();
  const { accountId } = useParams();
  const [currentId, setCurrentId] = useState<number>(-1);
  const [name, setName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [role, setRole] = useState<string>(USER_ROLE.CS);
  const [status, setStatus] = useState<string>(USER_STATUS.NORMAL);

  useEffect(() => {
    const id = parseInt(accountId!);
    setCurrentId(id);
    console.log(id);

    if (id > 0) {
      AccountService.account(id).then(({ data }) => {
        const result = data as AccountDetailResponse;
        console.log(data);
        if (result) {
          setEmail(result.email);
          setName(result.name);
          setRole(result.role);
          setStatus(result.status);
        }
      });
    }
  }, []);

  const onClickBack = () => {
    navigate(-1);
  };

  const handleSave = () => {
    AccountService.updateAccount(currentId, {
      name: name,
      email: email,
      role: role as USER_ROLE,
      status: status as USER_STATUS,
    }).then((response) => {
      const result = response.data as CommonResponse;
      if (result.result === RESULT_CODE.SUCCESS) {
        navigate(-1);
      }
    });
  };

  const onClickSave = () => {
    handleSave();
  };

  return (
    <>
      <Button color="gray" onClick={onClickBack}>
        목록으로
      </Button>
      <Space h="xl" />
      <TextInput
        label="이름"
        placeholder="이름"
        value={name}
        onChange={(event) => setName(event.currentTarget.value)}
      />
      <TextInput
        label="이메일"
        placeholder="이메일"
        value={email}
        onChange={(event) => setEmail(event.currentTarget.value)}
      />
      <Space h="md" />
      <Radio.Group value={role} onChange={setRole} label="권한">
        <Group mt="xs">
          <Radio value={USER_ROLE.MASTER} label={USER_ROLE_LABELS[USER_ROLE.MASTER]} />
          <Radio value={USER_ROLE.ADMIN} label={USER_ROLE_LABELS[USER_ROLE.ADMIN]} />
          <Radio value={USER_ROLE.CS} label={USER_ROLE_LABELS[USER_ROLE.CS]} />
        </Group>
      </Radio.Group>
      <Space h="md" />
      <Radio.Group value={status} onChange={setStatus} label="상태">
        <Group mt="xs">
          <Radio value={USER_STATUS.NORMAL} label={USER_STATUS_LABELS[USER_STATUS.NORMAL]} />
          <Radio value={USER_STATUS.STOP} label={USER_STATUS_LABELS[USER_STATUS.STOP]} />
        </Group>
      </Radio.Group>
      <Space h="xl" />
      <Group position="apart">
        <Button onClick={onClickSave}>수정하기</Button>
      </Group>
    </>
  );
}
