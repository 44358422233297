import {
  Button,
  Card,
  Flex,
  Grid,
  Group,
  Modal,
  NumberInput,
  Select,
  SelectItem,
  Space,
  Table,
  Text,
  TextInput,
  Title,
} from "@mantine/core";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
// import "App.css";
import { OrderService } from "services/OrderService";
import { Utils } from "utils/Utils";
import { ORDER_STATUS, ORDER_STATUS_LABELS } from "constants/OrderStatus";
import { ORDER_ITEM_STATUS_LABELS } from "constants/OrderItemStatus";
import { OrderDetailResponse } from "dto/OrderDto";
import { USER_ROLE } from "constants/UserRole";
import { Context as AuthContext } from "contexts/AuthContext";
import Postcode from "components/Postcode";
import { CommonResponse } from "dto/CommonDto";
import { RESULT_CODE } from "constants/ResultCode";
import { useDisclosure } from "@mantine/hooks";
import { ORDER_LOG_TYPE_LABELS } from "constants/OrderLogType";
import { BANK, BANK_CODE, BANK_LABELS } from "constants/BankCodes";

export default function OrderDetailPage() {
  const navigate = useNavigate();

  const [opened, { open, close }] = useDisclosure(false);
  const [popupType, setPopupType] = useState<"refund" | "cancel">("cancel");

  const { orderId } = useParams();

  const {
    state: { role },
  } = useContext(AuthContext);

  const [currentId, setCurrentId] = useState<number>(-1);
  const [refundAmount, setRefundAmount] = useState<number>(0);
  const [refundReason, setRefundReason] = useState<string>("");

  const [selectItems, setSelectItems] = useState<SelectItem[]>([]);

  const [info, setInfo] = useState<OrderDetailResponse>();

  const [orderStatus, setOrderStatus] = useState<ORDER_STATUS>();
  const [name, setName] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [postCode, setPostCode] = useState<string>("");
  const [address0, setAddress0] = useState<string>("");
  const [address1, setAddress1] = useState<string>("");
  const [memo, setMemo] = useState<string>("");

  const [selectBanks, setSelectBanks] = useState<SelectItem[]>([]);
  const [accountBank, setAccountBank] = useState<string>(BANK.BANK_01);
  const [accountName, setAccountName] = useState<string>("");
  const [accountNumber, setAccountNumber] = useState<string>("");

  useEffect(() => {
    let selects = [];
    for (const key in ORDER_STATUS) {
      selects.push({
        value: key,
        label: ORDER_STATUS_LABELS[key as ORDER_STATUS],
      });
    }
    setSelectItems(selects);

    selects = [];
    for (const key in BANK) {
      selects.push({
        value: key,
        label: BANK_LABELS[key as BANK],
      });
    }
    setSelectBanks(selects);

    console.log(selects);

    const id = parseInt(orderId!);
    setCurrentId(id);

    if (id > 0) {
      OrderService.order(id).then(({ data }) => {
        const result = data as OrderDetailResponse;
        console.log(data);
        if (result) {
          setInfo(result);

          setOrderStatus(result.status);
          setName(result.delivery.name);
          setPhone(result.delivery.phone);
          setEmail(result.delivery.email);
          setPostCode(result.delivery.post_code);
          setAddress0(result.delivery.address0);
          setAddress1(result.delivery.address1);
          setMemo(result.delivery.memo);
        }
      });
    }
  }, []);

  const onClickBack = () => {
    navigate(-1);
  };

  if (!info) {
    return <></>;
  }

  const onChangeAddress = (value: any) => {
    if (value.post_code) {
      setPostCode(value.post_code);
      setAddress0(value.address0);
      setAddress1("");
    }
    console.log(value);
  };

  const onClickModify = () => {
    OrderService.modifyOrder(currentId, {
      status: orderStatus === info.status ? undefined : orderStatus,
      name: name === info.delivery.name ? undefined : name,
      phone: phone === info.delivery.phone ? undefined : phone,
      email: email === info.delivery.email ? undefined : email,
      post_code: postCode === info.delivery.post_code ? undefined : postCode,
      address0: address0 === info.delivery.address0 ? undefined : address0,
      address1: address1 === info.delivery.address1 ? undefined : address1,
      memo: memo === info.delivery.memo ? undefined : memo,
    }).then((response) => {
      const result = response.data as CommonResponse;
      if (result.result === RESULT_CODE.SUCCESS) {
        navigate(-1);
      }
    });
  };

  const handleRefund = () => {
    if (!refundReason) {
      return;
    }

    if (info.payment?.method === "가상계좌") {
      if (!accountName || !accountNumber) {
        return;
      }
    }

    OrderService.refund(currentId, {
      reason: refundReason,
      amount: refundAmount,
      account_bank: BANK_CODE[accountBank as BANK],
      account_name: accountName,
      account_number: accountNumber,
    }).then((response) => {
      const result = response.data as CommonResponse;
      if (result.result === RESULT_CODE.SUCCESS) {
        close();
        navigate(-1);
      } else {
        if (result.msg) {
          alert(result.msg);
        } else {
          alert("환불에 실패했습니다.");
        }
      }
      console.log(result);
    });
  };

  const handleCancel = () => {
    if (!refundReason) {
      return;
    }

    if (info.payment?.method === "가상계좌") {
      if (!accountName || !accountNumber) {
        return;
      }
    }

    OrderService.refund(currentId, {
      reason: refundReason,
      account_bank: BANK_CODE[accountBank as BANK],
      account_name: accountName,
      account_number: accountNumber,
    }).then((response) => {
      const result = response.data as CommonResponse;
      if (result.result === RESULT_CODE.SUCCESS) {
        close();
        navigate(-1);
      } else {
        if (result.msg) {
          alert(result.msg);
        } else {
          alert("환불에 실패했습니다.");
        }
      }
      console.log(result);
    });
  };

  // 환불
  const onClickRefund = () => {
    setPopupType("refund");
    setRefundAmount(info.order_amount + info.delivery_amount);
    open();
  };

  // 주문 취소
  const onClickCancel = () => {
    setPopupType("cancel");
    open();
  };

  const renderOrderItems = info.items.map((item) => (
    <tr key={item.id}>
      <td>
        {item.product.title + " " + (item.option ? item.option.title : "")}
      </td>
      <td>{item.amount}</td>
      <td>{item.count}</td>
      <td>{ORDER_ITEM_STATUS_LABELS[item.status]}</td>
    </tr>
  ));

  const renderOrderLogs = info.logs.map((item, index) => (
    <tr key={index}>
      <td>{ORDER_LOG_TYPE_LABELS[item.type]}</td>
      <td>{item.user.name}</td>
      <td>{item.memo}</td>
      <td>
        {Utils.convertDateToYYMMDDHHmm(Utils.convertTimeToDate(item.created))}
      </td>
    </tr>
  ));

  const renderButtons = () => {
    if (role === USER_ROLE.LOGISTICS || !info) {
      return <></>;
    }

    if (info.status === ORDER_STATUS.PAYMENT_COMPLETE) {
      return (
        <Group position={"apart"}>
          <Button onClick={onClickModify}>수정하기</Button>
          <Button onClick={onClickCancel}>주문취소</Button>
        </Group>
      );
    }

    if (
      info.status === ORDER_STATUS.DELIVERY_COMPLETE ||
      info.status === ORDER_STATUS.DELIVERY_READY ||
      info.status === ORDER_STATUS.ORDER_COMPLETE
    ) {
      return (
        <Group position={"apart"}>
          <Button onClick={onClickModify}>수정하기</Button>
          <Button onClick={onClickRefund}>환불하기</Button>
        </Group>
      );
    }

    return (
      <Group>
        <Button onClick={onClickModify}>수정하기</Button>
      </Group>
    );
  };

  const renderPopup = () => {
    if (popupType === "cancel") {
      return (
        <>
          <Text>{"주문을 취소하겠습니까?"}</Text>
          <Space h="xl" />
          {info.payment?.method === "가상계좌" ? (
            <>
              <Select
                label="은행"
                placeholder="은행을"
                data={selectBanks}
                value={accountBank}
                onChange={(event) => setAccountBank(event!)}
              />
              <TextInput
                label="예금주명"
                placeholder="예금주명"
                value={accountName}
                onChange={(event) => setAccountName(event.currentTarget.value)}
              />
              <TextInput
                label="계좌번호"
                placeholder="계좌번호"
                value={accountNumber}
                onChange={(event) =>
                  setAccountNumber(event.currentTarget.value)
                }
              />
            </>
          ) : (
            <></>
          )}
          <TextInput
            label="사유"
            placeholder="사유"
            value={refundReason}
            onChange={(event) => setRefundReason(event.currentTarget.value)}
          />
          <Space h="xl" />
          <Group>
            <Button onClick={close}>취소</Button>
            <Button onClick={handleCancel}>확인</Button>
          </Group>
        </>
      );
    }

    return (
      <>
        <Flex gap={"md"} direction={"column"}>
          <Text>{"상품 가격 : " + info.order_amount + "원"}</Text>
          <Text>{"배송비 : " + info.delivery_amount + "원"}</Text>
          <Text>{"총 결제 내역 : " + info.payment?.amount + "원"}</Text>
        </Flex>
        <Space h="xl" />
        <Flex gap={"md"} align={"center"}>
          <Text>{"환불 금액"}</Text>
          <NumberInput
            value={refundAmount}
            parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
            formatter={(value) =>
              !Number.isNaN(parseFloat(value))
                ? `${value}`.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")
                : ""
            }
            onChange={(value) => {
              setRefundAmount(value ? value : 0);
            }}
            step={1000}
            min={0}
          />
          <Text fz="xl">원</Text>
        </Flex>
        {info.payment?.method === "가상계좌" ? (
          <>
            <Select
              label="은행"
              placeholder="은행을"
              data={selectBanks}
              value={accountBank}
              onChange={(event) => setAccountBank(event!)}
            />
            <TextInput
              label="예금주명"
              placeholder="예금주명"
              value={accountName}
              onChange={(event) => setAccountName(event.currentTarget.value)}
            />
            <TextInput
              label="계좌번호"
              placeholder="계좌번호"
              value={accountNumber}
              onChange={(event) => setAccountNumber(event.currentTarget.value)}
            />
          </>
        ) : (
          <></>
        )}
        <TextInput
          label="사유"
          placeholder="사유"
          value={refundReason}
          onChange={(event) => setRefundReason(event.currentTarget.value)}
        />
        <Space h="xl" />
        <Group>
          <Button onClick={close}>취소</Button>
          <Button onClick={handleRefund}>확인</Button>
        </Group>
      </>
    );
  };

  return (
    <>
      <Button color="gray" onClick={onClickBack}>
        목록으로
      </Button>
      <Modal opened={opened} onClose={close} centered>
        {renderPopup()}
      </Modal>
      <Space h="xl" />
      <Flex gap="md" align={"center"}>
        <Text fz="md" fw={700}>
          주문상태 :
        </Text>
        <Select
          data={selectItems}
          value={orderStatus}
          onChange={(event) => setOrderStatus(event! as ORDER_STATUS)}
          maxDropdownHeight={400}
        />
      </Flex>
      <Space h="xl" />
      <Card radius="md">
        <Title order={3}>주문 정보</Title>
        <Space h="xl" />
        <Grid>
          <Grid.Col span={12}>
            <Flex gap="md">
              <Text fz="md" fw={700}>
                회원ID :
              </Text>
              <Text fz="md">{info.user.id}</Text>
            </Flex>
          </Grid.Col>
          <Grid.Col span={6}>
            <Flex gap="md">
              <Text fz="md" fw={700}>
                회원명 :
              </Text>
              <Text fz="md">{info.user.name}</Text>
            </Flex>
          </Grid.Col>
          <Grid.Col span={6}>
            <Flex gap="md">
              <Text fz="md" fw={700}>
                주문 ID :
              </Text>
              <Text fz="md">{info?.uuid}</Text>
            </Flex>
          </Grid.Col>
          <Grid.Col span={6}>
            <Flex gap="md">
              <Text fz="md" fw={700}>
                주문일 :{" "}
              </Text>
              <Text fz="md">
                {Utils.convertDateToYYMMDDHHmm(
                  Utils.convertTimeToDate(info?.created!)
                )}
              </Text>
            </Flex>
          </Grid.Col>
          <Grid.Col span={6}>
            <Flex gap="md">
              <Text fz="md" fw={700}>
                결제 방식 :
              </Text>
              <Text fz="md">{info!.payment?.method}</Text>
            </Flex>
          </Grid.Col>
        </Grid>
      </Card>
      <Space h="md" />
      <Card radius="md">
        <Title order={3}>배송 정보</Title>
        <Space h="xl" />
        <Grid>
          <Grid.Col span={6}>
            <Flex gap="md">
              <Text fz="md" fw={700}>
                주문자 :
              </Text>
              <TextInput
                value={name}
                onChange={(e) => {
                  setName(e.currentTarget.value);
                }}
              />
            </Flex>
          </Grid.Col>
          <Grid.Col span={6}>
            <Flex gap="md">
              <Text fz="md" fw={700}>
                휴대폰 :
              </Text>
              <TextInput
                value={phone}
                onChange={(e) => {
                  setPhone(e.currentTarget.value);
                }}
              />
            </Flex>
          </Grid.Col>
          <Grid.Col span={12}>
            <Flex gap="md">
              <Text fz="md" fw={700}>
                이메일 :
              </Text>
              <TextInput
                value={email}
                onChange={(e) => {
                  setEmail(e.currentTarget.value);
                }}
              />
            </Flex>
          </Grid.Col>
          <Grid.Col span={12}>
            <Group>
              <Text fz="md" fw={700}>
                주소1 :
              </Text>
              <Text>{address0}</Text>
              <Postcode onChange={(value) => onChangeAddress(value)} />
            </Group>
          </Grid.Col>
          <Grid.Col span={12}>
            <Flex gap="md">
              <Text fz="md" fw={700}>
                주소2 :
              </Text>
              <TextInput
                value={address1}
                onChange={(e) => {
                  setAddress1(e.currentTarget.value);
                }}
              />
            </Flex>
          </Grid.Col>
          <Grid.Col span={6}>
            <Flex gap="md">
              <Text fz="md" fw={700}>
                메모 :
              </Text>
              <TextInput
                value={memo}
                onChange={(e) => {
                  setMemo(e.currentTarget.value);
                }}
              />
            </Flex>
          </Grid.Col>
        </Grid>
      </Card>
      <Space h="md" />
      <Table>
        <thead>
          <tr>
            <th>주문 상품</th>
            <th>금액</th>
            <th>수량</th>
            <th>상태</th>
          </tr>
        </thead>
        <tbody>{renderOrderItems}</tbody>
      </Table>
      <Space h="md" />
      <Title order={3}>로그</Title>
      <Table>
        <thead>
          <tr>
            <th>타입</th>
            <th>이름</th>
            <th>메모</th>
            <th>시간</th>
          </tr>
        </thead>
        <tbody>{renderOrderLogs}</tbody>
      </Table>
      <Space h="xl" />
      {renderButtons()}
    </>
  );
}
