import {
  Button,
  FileInput,
  Flex,
  Group,
  Image,
  NumberInput,
  Radio,
  Space,
  TextInput,
} from "@mantine/core";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
// import "App.css";
import { CommonService } from "services/CommonService";
import { CommonResponse, UploadImageResponse } from "dto/CommonDto";
import { RESULT_CODE } from "constants/ResultCode";
import { BannerDetailDto } from "dto/BannerDto";
import { USER_ROLE } from "constants/UserRole";
import { Context as AuthContext } from "contexts/AuthContext";
import { BannerService } from "services/BannerService";
import { BANNER_TYPE } from "constants/BannerType";

export default function BannerDetailPage() {
  const navigate = useNavigate();

  const { bannerId } = useParams();

  const {
    state: { role },
  } = useContext(AuthContext);

  const [currentId, setCurrentId] = useState<number>(-1);

  const [publish, setPublish] = useState("false");
  const [thumbnail, setThumbnail] = useState<string>("");
  const [type, setType] = useState<BANNER_TYPE>(BANNER_TYPE.MAIN);
  const [path, setPath] = useState<string>("");
  const [file, setFile] = useState<File>();

  useEffect(() => {
    const id = parseInt(bannerId!);
    setCurrentId(id);

    if (id > 0) {
      BannerService.banner(id).then((response) => {
        const result = response.data as BannerDetailDto;
        if (result) {
          setType(result.type);
          setThumbnail(result.thumbnail);
          setPath(result.path);
          setPublish("" + result.publish);
        }
        console.log(result);
      });
    }
  }, []);

  useEffect(() => {
    if (file) {
      const formData = new FormData();
      formData.append("file", file);
      CommonService.uploadImage(formData).then((response) => {
        const result = response.data as UploadImageResponse;
        console.log(result);
        setThumbnail(result.url);
      });
    }
    return () => {};
  }, [file]);

  const onClickBack = () => {
    navigate(-1);
  };

  const handleSave = () => {
    BannerService.saveBanner({
      id: currentId,
      publish: publish === "true",
      thumbnail: thumbnail,
      type: type,
      path: path,
    }).then((response) => {
      const result = response.data as CommonResponse;
      if (result.result === RESULT_CODE.SUCCESS) {
        navigate(-1);
      }
    });
  };

  const onClickSave = () => {
    handleSave();
  };

  const onClickModify = () => {
    handleSave();
  };

  const onClickDelete = () => {
    BannerService.deleteBanner(currentId).then((response) => {
      const result = response.data as CommonResponse;
      if (result.result === RESULT_CODE.SUCCESS) {
        navigate(-1);
      }
    });
  };

  const renderThumbnail = () => {
    if (!thumbnail) {
      return <></>;
    }

    return (
      <Image
        width={80}
        height={80}
        fit="contain"
        radius="md"
        src={thumbnail}
        alt="썸네일"
      />
    );
  };

  const renderButtons = () => {
    if (currentId > 0) {
      return (
        <Group position="apart">
          <Button onClick={onClickModify}>수정하기</Button>
          {role === USER_ROLE.MASTER ? (
            <Button onClick={onClickDelete}>삭제하기</Button>
          ) : (
            <></>
          )}
        </Group>
      );
    }

    return (
      <Group position="apart">
        <Button onClick={onClickSave}>저장하기</Button>
      </Group>
    );
  };

  return (
    <>
      <Button color="gray" onClick={onClickBack}>
        목록으로
      </Button>
      <Space h="xl" />
      <Space h="xl" />
      <Radio.Group value={publish} onChange={setPublish} label="공개여부">
        <Group mt="xs">
          <Radio value="true" label="공개" />
          <Radio value="false" label="비공개" />
        </Group>
      </Radio.Group>
      <Radio.Group
        value={type}
        onChange={(value) => setType(value as BANNER_TYPE)}
        label="타입"
      >
        <Group mt="xs">
          <Radio value={BANNER_TYPE.MAIN} label="메인" />
          <Radio value={BANNER_TYPE.MINI} label="미니" />
        </Group>
      </Radio.Group>
      <Space h="md" />
      <TextInput
        label="링크"
        value={path}
        onChange={(e) => setPath(e.currentTarget.value)}
      />
      <Flex gap="md" align={"center"}>
        {renderThumbnail()}
        <FileInput
          label="썸네일"
          placeholder="파일을 업로드 해주세요."
          value={file}
          onChange={(e) => setFile(e as File)}
        />
      </Flex>
      <Space h="xl" />
      <Space h="xl" />
      {renderButtons()}
    </>
  );
}
