import { customAxios } from "./CustomAxiosLoader";
import { UpdateAccountRequest } from "dto/AccountDto";

const accountList = (
  page: number,
  limit: number
) => {
  return customAxios.get(`/accounts`, {
    params: {
      limit: limit,
      offset: limit * (page - 1),
    },
  });
};

const account = (accountId: number) => {
  return customAxios.get(`/accounts/${accountId}`);
};

const isExistAccount = (email: string) => {
  return customAxios.get(`/accounts/is_exist/${email}`);
}

const updateAccount = (userId: number, req: UpdateAccountRequest) => {
  return customAxios.put(`/accounts/${userId}`, req);
};

export const AccountService = {
  accountList,
  account,
  isExistAccount,
  updateAccount,
};
