import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Flex, Pagination, Space, Table, Title } from "@mantine/core";
import { ProductListResponse, ProductSimpleDto } from "dto/ProductDto";
import { ProductsService } from "services/ProductsService";
import { PRODUCT_TYPE_LABELS } from "constants/ProductType";

const LIST_PAGE_LIMIT = 10;
export default function ProductListPage() {
  const navigate = useNavigate();

  const [items, setItems] = useState<ProductSimpleDto[]>([]);
  const [totalPage, setTotalPage] = useState<number>(1);
  const [currentPage, setCurrentPage] = useState<number>(1);

  useEffect(() => {
    ProductsService.productList(currentPage, LIST_PAGE_LIMIT).then(
      (response) => {
        const result = response.data as ProductListResponse;
        setItems(result.items);
        setTotalPage(Math.ceil(result.total / LIST_PAGE_LIMIT));
        console.log(result);
      }
    );
  }, [currentPage]);

  const onClickCreate = () => {
    navigate("-1");
  };

  const onClickTable = (productId: number) => {
    navigate("" + productId);
  };

  // <th>상품번호</th>
  // <th>상품명</th>
  // <th>타입</th>
  // <th>아티스트</th>
  // <th>가격</th>
  // <th>할인가</th>
  // <th>사용</th>
  const rows = items.map((item) => (
    <tr key={item.id} onClick={() => onClickTable(item.id)}>
      <td>{item.uuid}</td>
      <td>{item.title}</td>
      <td>{PRODUCT_TYPE_LABELS[item.type]}</td>
      <td>{item.artist.name}</td>
      <td>{item.amount.origin}</td>
      <td>{item.amount.amount}</td>
      <td>{item.publish ? "공개" : "비공개"}</td>
    </tr>
  ));

  return (
    <>
      <Title order={1}>상품 목록</Title>
      <Space h="md" />
      <Flex mih={50} gap="md" justify="flex-end" align="center" wrap="wrap">
        <Button onClick={onClickCreate}>상품등록</Button>
      </Flex>
      <Space h="md" />
      <Table>
        <thead>
          <tr>
            <th>상품번호</th>
            <th>상품명</th>
            <th>타입</th>
            <th>아티스트</th>
            <th>가격</th>
            <th>할인가</th>
            <th>공개</th>
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </Table>
      <Space h="md" />
      <Pagination
        position="center"
        value={currentPage}
        onChange={setCurrentPage}
        total={totalPage}
      />
    </>
  );
}
