import { Navigate, Outlet, useLocation } from "react-router-dom";
import { Context as AuthContext } from "./contexts/AuthContext";
import PATH from "./Path";
import { useContext } from "react";
import { USER_ROLE } from "./constants/UserRole";

interface Props {
  allowedRoles: USER_ROLE[];
}

export const AuthRoute = ({ allowedRoles }: Props) => {
  const {
    state: { role },
  } = useContext(AuthContext);

  const location = useLocation();

  // return allowedRoles.find((role) => auth.role.includes(role)) ? (

  return allowedRoles.find((r) => role === r) ? (
    <Outlet />
  ) : (
    <Navigate to={PATH.Home} state={{ from: location }} replace />
  );

  return <Outlet />;
};
