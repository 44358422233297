import React, { useState, useEffect } from "react";
import { Button } from "@mantine/core";
import { useDaumPostcodePopup } from "react-daum-postcode";

let initial = false;

type Value = {
  post_code?: string;
  address0?: string;
  address1?: string;
};

interface Props {
  onChange: (value: Value) => void;
}

function Postcode({ onChange }: Props) {
  const scriptUrl =
    "https://t1.daumcdn.net/mapjsapi/bundle/postcode/prod/postcode.v2.js";
  const [address, setAddress] = useState<string>("");
  const [postcode, setPostcode] = useState<string>("");
  const open = useDaumPostcodePopup(scriptUrl);

  const handleClick = () => {
    open({ onComplete: handleComplete });
    initial = true;
  };

  const handleComplete = (data: any) => {
    let fullAddress = data.address;
    let extraAddress = "";

    if (data.addressType === "R") {
      if (data.bname !== "") {
        extraAddress += data.bname;
      }
      if (data.buildingName !== "") {
        extraAddress +=
          extraAddress !== "" ? `, ${data.buildingName}` : data.buildingName;
      }
      fullAddress += extraAddress !== "" ? ` (${extraAddress})` : "";
    }
    setAddress(fullAddress);
    setPostcode(data.zonecode);
  };

  useEffect(() => {
    if (initial) {
      onChange({ post_code: postcode, address0: address });
    }
  }, [address, postcode]);

  return (
    <Button onClick={handleClick} type="button" color="gray">
      주소 검색
    </Button>
  );
}

export default Postcode;
