import { ReactNode, useContext, useEffect, useState } from "react";
import styled from "styled-components";
import SignButton from "components/auth/SignButton";
import { ReactComponent as Logo } from "assets/img/logo.svg";
import { SIGN_TYPE } from "constants/SignType";
import { useLocation, useNavigate } from "react-router-dom";
import { AxiosError } from "axios";
import PATH from "Path";
import { MeResponse, TokenResponse } from "dto/AuthDto";
import { Context as AuthContext } from "contexts/AuthContext";
import { UserService } from "services/UserService";
import { RESULT_CODE } from "constants/ResultCode";
import { customAxios } from "services/CustomAxiosLoader";
import { LoadingOverlay } from "@mantine/core";

const SignIn = () => {
  const navigate = useNavigate();

  const [authorizationUrl, setAuthorizationUrl] = useState<string>();
  const [popup, setPopup] = useState<Window | null>();

  const { updateToken } = useContext(AuthContext);

  const [visible, setVisible] = useState<boolean>(false);

  useEffect(() => {
    if (authorizationUrl) {
      const width = 500;
      const height = 400;
      const left = window.screenX + (window.outerWidth - width) / 2;
      const top = window.screenY + (window.outerHeight - height) / 2;
      const popup = window.open(
        authorizationUrl,
        "로그인 중...",
        `width=${width},height=${height},left=${left},top=${top}`
      );
      setPopup(popup);

      setAuthorizationUrl("");
    }
    return () => {};
  }, [authorizationUrl]);

  // 로그인 팝입이 열리면 로그인 로직을 처리합니다.
  useEffect(() => {
    if (!popup) {
      return;
    }

    const onListener = (e: any) => {
      // if (e.origin !== window.location.origin) {
      //   return;
      // }

      if (e.data === RESULT_CODE.FAIL) {
      } else {
        handleSignIn(e.data);
      }

      popup?.close();
      setPopup(null);
    };

    window.addEventListener("message", onListener, false);

    return () => {
      console.log("이벤트 제거");
      window.removeEventListener("message", onListener);
      popup?.close();
      setPopup(null);
    };
  }, [popup]);

  const handleSignIn = (token: TokenResponse) => {
    UserService.me(token.access_token)
      .then(({ data }) => {
        const result = data as MeResponse;
        console.log(result);
        updateToken(token);
        navigate(PATH.Admin);
      })
      .catch((e: AxiosError) => {
        console.log("log", e);
        setVisible(false);
      });
  };

  const handleOAuth = (link: string) => {
    setVisible(true);

    customAxios
      .get(link)
      .then((response) => {
        const { authorization_url } = response.data;

        setAuthorizationUrl(authorization_url);
      })
      .catch((e: AxiosError) => {
        console.log("log", e);
        setVisible(false);
      });
  };

  const onClickKakao = () => {
    handleOAuth(
      "/auth/kakao/authorize" + "?callback_url=" + window.location + "kakao"
    );
  };

  const onClickNaver = () => {
    handleOAuth(
      "/auth/naver/authorize" + "?callback_url=" + window.location + "naver"
    );
  };

  const onClickGoogle = () => {
    handleOAuth(
      "/auth/google/authorize" + "?callback_url=" + window.location + "google"
    );
  };

  return (
    <Container>
      <LoadingOverlay visible={visible} overlayBlur={2} />
      <LogoSection>
        <Logo />
        <Text>
          팬과 아티스트가 함께
          <br />
          <Bold>우리</Bold>로 만나는 곳
        </Text>
      </LogoSection>
      <SignInSection>
        <SignButton type={SIGN_TYPE.KAKAO} onClick={onClickKakao} />
        <SignButton type={SIGN_TYPE.NAVER} onClick={onClickNaver} />
        <SignButton type={SIGN_TYPE.GOOGLE} onClick={onClickGoogle} />
      </SignInSection>
    </Container>
  );
};

interface Props {
  api_callback_url: string;
  error_message: string;
  children: ReactNode;
}

const CallbackOAuth = ({ api_callback_url, children }: Props) => {
  const location = useLocation();

  useEffect(() => {
    customAxios
      .get(api_callback_url + location.search)
      .then(({ data }) => {
        const result = data as TokenResponse;

        window.opener.postMessage(result, window.location.origin);
      })
      .catch(({ response }) => {
        window.opener.postMessage(RESULT_CODE.FAIL, window.location.origin);
      });
  }, []);

  return <div>{children}</div>;
};

const CallbackKakao = () => {
  return (
    <CallbackOAuth
      api_callback_url="/auth/kakao/callback"
      error_message="Failed to authroize with Kakao"
    >
      Waiting for Kakao Sign-in to complete...
    </CallbackOAuth>
  );
};

const CallbackNaver = () => {
  return (
    <CallbackOAuth
      api_callback_url="/auth/naver/callback"
      error_message="Failed to authroize with Naver"
    >
      Waiting for Naver Sign-in to complete...
    </CallbackOAuth>
  );
};

const CallbackGoogle = () => {
  return (
    <CallbackOAuth
      api_callback_url="/auth/google/callback"
      error_message="Failed to authroize with Google"
    >
      Waiting for Google Sign-in to complete...
    </CallbackOAuth>
  );
};

export default {
  SignIn,
  Redirects: {
    Google: CallbackGoogle,
    Naver: CallbackNaver,
    Kakao: CallbackKakao,
  },
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 15vh;
  width: 100%;
  height: 100%;
  background: linear-gradient(137deg, #4122ff 0%, #932eff 100%);
`;

const LogoSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 26px;
  margin-top: 10vh;
`;

const Text = styled.span`
  text-align: center;
  color: #fff;
  font-size: 28px;
  font-weight: 300;
  line-height: normal;
`;

const Bold = styled.span`
  font-weight: 700;
`;

const SignInSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 13px;
`;
