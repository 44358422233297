import { customAxios } from "./CustomAxiosLoader";
import { NoticeDetailDto } from "dto/NoticeDto";

const noticeList = (page: number, limit: number) => {
  return customAxios.get(`/notices`, {
    params: { limit: limit, offset: limit * (page - 1) },
  });
};

const notice = (noticeId: number) => {
  return customAxios.get(`/notices/${noticeId}`);
};

const saveNotice = (req: NoticeDetailDto) => {
  return customAxios.post(`/notices`, req);
};

const deleteNotice = (noticeId: number) => {
  return customAxios.delete(`/notices/${noticeId}`);
};

export const NoticeService = {
  noticeList,
  saveNotice,
  notice,
  deleteNotice,
};
