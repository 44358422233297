import {
  Button,
  Checkbox,
  FileInput,
  Flex,
  Group,
  Image,
  NumberInput,
  Radio,
  Select,
  SelectItem,
  Space,
  Table,
  Text,
  TextInput,
} from "@mantine/core";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
// import "App.css";
import { CommonService } from "services/CommonService";
import { CommonResponse } from "dto/CommonDto";
import { RESULT_CODE } from "constants/ResultCode";
import { ProductsService } from "services/ProductsService";
import { PRODUCT_TYPE, PRODUCT_TYPE_LABELS } from "constants/ProductType";
import {
  ProductAmountDto,
  ProductDetailDto,
  ProductDescriptionDto,
  ProductOptionDto,
} from "dto/ProductDto";
import { DateTimePicker, DateValue } from "@mantine/dates";
import { Utils } from "utils/Utils";
import { Context as AuthContext } from "contexts/AuthContext";
import { USER_ROLE } from "constants/UserRole";
import CustomEditor from "components/CustomEditor";

export enum AMOUNT_TYPE {
  ORIGIN,
  PERCENT,
  FIXED,
}

export default function ProductDetailPage() {
  const navigate = useNavigate();

  const { productId } = useParams();

  const {
    state: { role },
  } = useContext(AuthContext);

  const [currentId, setCurrentId] = useState<number>(-1);

  const [selectItems, setSelectItems] = useState<SelectItem[]>([]);

  const [publish, setPublish] = useState("false");
  const [artist, setArtist] = useState<string>("0");
  const [uuid, setUuid] = useState<string>("0");
  const [title, setTitle] = useState<string>("");
  const [thumbnail, setThumbnail] = useState<string>("");
  const [type, setType] = useState<PRODUCT_TYPE>(PRODUCT_TYPE.PHOTO_CARD);
  const [options, setOptions] = useState<ProductOptionDto[]>([]);
  const [description, setDescription] = useState<ProductDescriptionDto>({
    id: 0,
    thumbnail: "",
    description: "",
  });
  const [amount, setAmount] = useState<ProductAmountDto>({
    id: 0,
    origin: 0,
    amount: 0,
    is_discount: false,
    percent: 0,
    fixed: 0,
  });
  const [file0, setFile0] = useState<File>();
  const [file1, setFile1] = useState<File>();
  const [max, setMax] = useState<number>(10);
  const [stock, setStock] = useState<number>(0);
  const [start, setStart] = useState<Date>(new Date());
  const [end, setEnd] = useState<Date>(new Date());
  const [condition, setCondition] = useState<number>(0)

  useEffect(() => {
    const id = parseInt(productId!);
    setCurrentId(id);

    CommonService.artistsForSelect().then((items) => {
      if (id < 0 && items.length > 0) {
        setArtist(items[0].value);
      }

      setSelectItems(items);
    });

    if (id > 0) {
      ProductsService.product(id).then((response) => {
        console.log(response.data);
        const result = response.data as ProductDetailDto;
        if (result) {
          setPublish("" + result.publish);
          setArtist("" + result.artist_id);
          setUuid(result.uuid);
          setTitle(result.title);
          setThumbnail(result.thumbnail);
          setType(result.type);
          setMax(result.max);
          setAmount(result.amount);
          setDescription(result.description);
          if (result.options.length) {
            setOptions(
              result.options.map((option) => {
                const condition = option.stock - (option.condition ? option.condition : 0);
                return {
                  id: option.id,
                  title: option.title,
                  stock: option.stock,
                  condition: condition
                };
              }),
            );
          }
          setStock(result.stock);
          setStart(Utils.convertTimeToDate(result.start));
          setEnd(Utils.convertTimeToDate(result.end));
          setCondition(result.condition);
        }
      });
    } else {
      setDescription({
        id: 0,
        thumbnail: "",
        description: "",
      });
    }
  }, []);

  useEffect(() => {
    console.log(description);
    return () => {};
  }, [description]);

  useEffect(() => {
    if (file0) {
      uploadImage(file0, (url: string) => setThumbnail(url));
    }
    return () => {};
  }, [file0]);

  useEffect(() => {
    if (file1) {
      uploadImage(file1, (url: string) => {
        let tempDescription = { ...description! };
        tempDescription.thumbnail = url;
        setDescription(tempDescription);
      });
    }
    return () => {};
  }, [file1]);

  // 총 재고량 자동 설정
  // useEffect(() => {
  //   let value = 0;
  //   let condition = 0;
  //
  //   for (const option of options) {
  //     value += option.stock;
  //     condition += option.condition!;
  //   }
  //
  //   setStock(value);
  //   setCondition((condition ? condition : value));
  // }, [options]);

  const uploadImage = async (file: File, onComplete: (url: string) => void) => {
    const formData = new FormData();
    formData.append("file", file);
    const result = await CommonService.uploadImage(formData);
    console.log(result);
    onComplete(result.data.url);
  };

  const onClickBack = () => {
    navigate(-1);
  };

  const handleSave = () => {
    if (!title) {
      alert("제목을 입력해야합니다.");
      return;
    }

    console.log(description);
    ProductsService.saveProduct({
      id: currentId,
      artist_id: parseInt(artist),
      publish: publish === "true",
      type: type,
      uuid: uuid,
      title: title,
      thumbnail: thumbnail,
      description: description!,
      amount: amount,
      options: options,
      max: max,
      stock: stock,
      start: Utils.convertDateToTime(start),
      end: Utils.convertDateToTime(end),
      condition: condition,
    }).then((response) => {
      const result = response.data as CommonResponse;
      if (result.result === RESULT_CODE.SUCCESS) {
        navigate(-1);
      }
    });
  };

  const onClickSave = () => {
    handleSave();
  };

  const onClickModify = () => {
    handleSave();
  };

  const onClickDelete = () => {
    ProductsService.deleteProduct(currentId).then((response) => {
      const result = response.data as CommonResponse;
      if (result.result === RESULT_CODE.SUCCESS) {
        navigate(-1);
      }
    });
  };

  const onAppendOption = () => {
    let tempOptions = [...options];
    tempOptions.push({ title: "", id: 0, stock: 0 });
    setOptions(tempOptions);
  };

  const deleteOption = (index: number) => {
    const tempOptions = [...options];
    tempOptions.splice(index, 1);
    setOptions(tempOptions);
  };

  const onDeleteOption = () => {};

  const onChangeAmount = (type: AMOUNT_TYPE, value: number | "") => {
    const currentValue = value ? value : 0;
    let tempAmount = { ...amount };
    if (type === AMOUNT_TYPE.ORIGIN) {
      tempAmount.origin = currentValue;
      tempAmount.fixed = currentValue * (tempAmount.percent / 100);
    } else if (type === AMOUNT_TYPE.FIXED) {
      tempAmount.fixed = currentValue;
      tempAmount.percent = Math.round((currentValue / tempAmount.origin) * 100);
    } else if (type === AMOUNT_TYPE.PERCENT) {
      tempAmount.percent = currentValue;
      tempAmount.fixed = tempAmount.origin * (currentValue / 100);
    }

    tempAmount.amount = tempAmount.origin - tempAmount.fixed;
    setAmount(tempAmount);
  };

  const onChangeStartDate = (input: DateValue) => {
    if (input) {
      setStart(input as Date);
    }
  };

  const onChangeEndDate = (input: DateValue) => {
    if (input) {
      setEnd(input as Date);
    }
  };

  const renderButtons = () => {
    if (currentId > 0) {
      return (
        <Group position="apart">
          <Button onClick={onClickModify}>수정하기</Button>
          {role === USER_ROLE.MASTER ? (
            <Button onClick={onClickDelete}>삭제하기</Button>
          ) : (
            <></>
          )}
        </Group>
      );
    }

    return (
      <Group position="apart">
        <Button onClick={onClickSave}>저장하기</Button>
      </Group>
    );
  };

  const renderOptions = options.map((option, index: number) => (
    <tr key={index}>
      <td>{index + 1}</td>
      <td>
        <TextInput
          value={option.title}
          onChange={(e) => {
            let tempOptions = [...options];
            tempOptions[index].title = e.currentTarget.value;
            setOptions(tempOptions);
          }}
        />
      </td>
      <td>
        <NumberInput
          value={option.stock}
          parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
          formatter={(value) =>
            !Number.isNaN(parseFloat(value))
              ? `${value}`.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")
              : ""
          }
          onChange={(value) => {
            let tempOptions = [...options];
            const currentValue = value ? value : 0;
            const modifyStock = currentValue - tempOptions[index].stock;

            tempOptions[index].stock = currentValue;
            tempOptions[index].condition = tempOptions[index].condition! + modifyStock;
            setOptions(tempOptions);
          }}
          min={0}
        />
      </td>
      <td>
        <Text>
          {option.condition}
        </Text>
      </td>
      <td>
        <Button onClick={() => deleteOption(index)}>삭제</Button>
      </td>
    </tr>
  ));

  const renderImage = (url?: string) => {
    if (!url) {
      return <></>;
    }

    return (
      <Image width={180} height={180} fit="contain" radius="md" src={url} />
    );
  };

  if (!description) {
    return <></>;
  }

  return (
    <>
      <Button color="gray" onClick={onClickBack}>
        목록으로
      </Button>
      <Space h="xl" />
      <Space h="xl" />
      <Radio.Group value={publish} onChange={setPublish} label="공개여부">
        <Group mt="xs">
          <Radio value="true" label="공개" />
          <Radio value="false" label="비공개" />
        </Group>
      </Radio.Group>
      <Space h="md" />
      <Select
        label="아티스트"
        placeholder="아티스트를 선택 해주세요."
        data={selectItems}
        value={artist}
        onChange={(event) => setArtist(event!)}
      />
      <Space h="md" />
      <Radio.Group
        value={type}
        onChange={(value) => setType(value as PRODUCT_TYPE)}
        label="타입"
      >
        <Group mt="xs">
          <Radio
            value={PRODUCT_TYPE.PHOTO_CARD}
            label={PRODUCT_TYPE_LABELS.PHOTO_CARD}
          />
          <Radio value={PRODUCT_TYPE.GOODS} label={PRODUCT_TYPE_LABELS.GOODS} />
          <Radio
            value={PRODUCT_TYPE.TICKET}
            label={PRODUCT_TYPE_LABELS.TICKET}
          />
          <Radio value={PRODUCT_TYPE.ETC} label={PRODUCT_TYPE_LABELS.ETC} />
        </Group>
      </Radio.Group>
      <Space h="md" />
      <TextInput
        label="상품명"
        placeholder="상품명"
        value={title}
        onChange={(event) => setTitle(event.currentTarget.value)}
      />
      <Space h="md" />
      <DateTimePicker
        label="판매 시작 시간"
        placeholder="판매 시작 시간"
        valueFormat="YYYY년 MM월 DD일 HH시 mm분"
        maw={400}
        value={start}
        locale={"ko"}
        monthLabelFormat={"YYYY년 MM월"}
        onChange={(e) => onChangeStartDate(e)}
      />
      <Space h="md" />
      <DateTimePicker
        label="판매 종료 시간"
        placeholder="판매 시작 시간"
        valueFormat="YYYY년 MM월 DD일 HH시 mm분"
        maw={400}
        value={end}
        locale={"ko"}
        monthLabelFormat={"YYYY년 MM월"}
        onChange={(e) => onChangeEndDate(e)}
      />
      <Space h="md" />
      <Button onClick={onAppendOption}>옵션 추가</Button>
      <Table>
        <thead>
          <tr>
            <th>번호</th>
            <th>옵션명</th>
            <th>재고</th>
            <th>잔여량</th>
            <th>삭제</th>
          </tr>
        </thead>
        <tbody>{renderOptions}</tbody>
      </Table>
      <Space h="xl" />
      <Flex gap="md" align={"center"}>
        {renderImage(thumbnail)}
        <FileInput
          label="샵 리스트 썸네일"
          placeholder="파일을 업로드 해주세요."
          value={file0}
          onChange={(e) => setFile0(e as File)}
        />
      </Flex>
      <Space h="md" />
      <Flex gap="md" align={"center"}>
        {renderImage(description?.thumbnail)}
        <FileInput
          label="상품 상세 대표 이미지"
          placeholder="파일을 업로드 해주세요."
          value={file1}
          onChange={(e) => setFile1(e as File)}
        />
      </Flex>
      <Space h="md" />
      <Flex gap={"md"} align={"flex-end"}>
        <NumberInput
          label="판매가"
          value={amount.origin}
          parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
          formatter={(value) =>
            !Number.isNaN(parseFloat(value))
              ? `${value}`.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")
              : ""
          }
          onChange={(value) => {
            onChangeAmount(AMOUNT_TYPE.ORIGIN, value);
          }}
          step={1000}
          min={0}
        />
        <Text fz="xl">원</Text>
      </Flex>
      <Space h="md" />
      <Checkbox
        label="할인 적용"
        checked={amount.is_discount}
        onChange={(e) => {
          let tempAmount = { ...amount };
          tempAmount.is_discount = e.target.checked;
          setAmount(tempAmount);
        }}
      />
      <Flex gap={"md"} align={"flex-end"}>
        <NumberInput
          label="퍼센트"
          disabled={!amount.is_discount}
          value={amount.percent}
          onChange={(value) => {
            onChangeAmount(AMOUNT_TYPE.PERCENT, value);
          }}
          max={100}
          min={0}
        />
        <Text fz="xl"> % = </Text>
        <NumberInput
          label="고정가"
          disabled={!amount.is_discount}
          value={amount.fixed}
          parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
          formatter={(value) =>
            !Number.isNaN(parseFloat(value))
              ? `${value}`.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")
              : ""
          }
          onChange={(value) => {
            onChangeAmount(AMOUNT_TYPE.FIXED, value);
          }}
          max={amount.origin}
          min={0}
        />
        <Text fz="xl">원</Text>
      </Flex>
      <Space h="md" />
      <Flex gap={"md"} align={"flex-end"}>
        <NumberInput
          label="최대판매수량"
          value={max}
          onChange={(value) => {
            setMax(value ? value : 0);
          }}
        />
        <Text fz="xl">개</Text>
      </Flex>
      <Flex gap={"md"} align={"flex-end"}>
        <NumberInput
          label="재고"
          value={stock}
          onChange={(value) => {
            let tempStock = stock;
            const currentValue = value ? value : 0;
            const modifyStock = currentValue - tempStock;

            setStock(currentValue);
            setCondition(condition + modifyStock);
          }}
          min={0}
        />
        <Text fz="xl">개</Text>
      </Flex>
      <CustomEditor
        value={description?.description}
        onChange={(value) => {
          let tempDescription = { ...description };
          console.log(value);
          tempDescription.description = value;
          setDescription(tempDescription);
        }}
      />
      <Space h="xl" />
      {renderButtons()}
    </>
  );
}
