import styled, { css } from "styled-components";
import { ReactComponent as Kakao } from "assets/icon/kakao-logo.svg";
import { ReactComponent as Naver } from "assets/icon/naver-logo.svg";
import { ReactComponent as Google } from "assets/icon/google-logo.svg";
import { SIGN_TYPE } from "constants/SignType";

const snsOptionList = {
  [SIGN_TYPE.KAKAO]: {
    bgColor: "#FEE500",
    color: "rgba(0, 0, 0, 0.85)",
    icon: <Kakao />,
    label: "카카오",
  },
  [SIGN_TYPE.NAVER]: {
    bgColor: "#03C75A",
    color: "#FFFFFF",
    icon: <Naver />,
    label: "네이버",
  },
  [SIGN_TYPE.GOOGLE]: {
    bgColor: "#FFFFFF",
    color: "rgba(0, 0, 0, 0.54)",
    icon: <Google />,
    label: "구글",
  },
};

interface Props {
  type: SIGN_TYPE;
  onClick: () => void;
}

interface StyledProps {
  bgcolor?: string;
  color?: string;
  sort?: SIGN_TYPE;
}

function SignButton({ type, onClick }: Props) {
  return (
    <Container
      onClick={onClick}
      bgcolor={snsOptionList[type].bgColor}
      sort={type}
    >
      {snsOptionList[type].icon}
      <Label color={snsOptionList[type].color}>
        {snsOptionList[type].label}로 시작하기
      </Label>
    </Container>
  );
}

export default SignButton;

const Container = styled.button<StyledProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ sort }) => {
    switch (sort) {
      case SIGN_TYPE.KAKAO:
        return css`
          gap: 10px;
          padding-right: 6px;
        `;
      case SIGN_TYPE.NAVER:
        return css`
          gap: 13px;
          padding-right: 3px;
        `;
      case SIGN_TYPE.GOOGLE:
        return css`
          gap: 9px;
          padding-right: 7px;
        `;
      default:
        return "0";
    }
  }};
  width: 312px;
  height: 58px;
  border-radius: 6px;
  box-sizing: border-box;
  background: ${({ bgcolor }) => bgcolor};
  cursor: pointer;
  user-select: none;
`;

const Label = styled.span<StyledProps>`
  color: ${({ color }) => color};
  font-size: 20px;
  font-weight: 500;
`;
