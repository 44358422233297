import { customAxios } from "./CustomAxiosLoader";
import { CollectionDetailDto } from "dto/CollectionDto";

const collectionList = (page: number, limit: number) => {
  return customAxios.get(`/collections`, {
    params: { limit: limit, offset: limit * (page - 1) },
  });
};

const collection = (collectionId: number) => {
  return customAxios.get(`/collections/${collectionId}`);
};

const saveCollection = (req: CollectionDetailDto) => {
  return customAxios.post(`/collections`, req);
};

const deleteCollection = (collectionId: number) => {
  return customAxios.delete(`/collections/${collectionId}`);
};

export const CollectionService = {
  collectionList,
  saveCollection,
  collection,
  deleteCollection,
};
