import { customAxios } from "./CustomAxiosLoader";
import { ArtistForSelectResponse } from "dto/CommonDto";
import { SelectItem } from "@mantine/core";

const uploadImage = (formData: FormData) => {
  const header = {
    headers: {
      // accessToken: getAccessToken(),
      "Content-Type": "multipart/form-data",
    },
  };

  return customAxios.post(`/image/upload`, formData, header);
};

const artistsForSelect = async () => {
  const response = await customAxios.get(`/artists/select`);
  const result = response.data as ArtistForSelectResponse[];
  let items: SelectItem[] = [];
  result.forEach((artist) => {
    items.push({
      value: "" + artist.id,
      label: artist.name,
    });
  });
  return items;
};

export const CommonService = {
  uploadImage,
  artistsForSelect,
};
