import React, { useEffect, useState } from "react";
import {
  Button,
  Flex,
  Overlay,
  Pagination,
  Space,
  Table,
  Text,
  Title,
} from "@mantine/core";
import { MonthPickerInput } from "@mantine/dates";
import { ProductListResponse, ProductSimpleDto } from "dto/ProductDto";
import { ProductsService } from "services/ProductsService";
import { PRODUCT_TYPE_LABELS } from "constants/ProductType";
import { StatsService } from "services/StatsService";
import { ProductStatDto } from "dto/StatsDto";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";
import {UrlResponse} from "../../dto/CommonDto";
import {RESULT_CODE} from "../../constants/ResultCode";
import {Utils} from "../../utils/Utils";

const LIST_PAGE_LIMIT = 10;
interface GraphData {
  name: string;
  complete: number;
  cancel: number;
}
export default function ProductStatsPage() {
  const [date, setDate] = useState<Date | null>(null);
  const [visible, setVisible] = useState<boolean>(false);
  const [items, setItems] = useState<ProductSimpleDto[]>([]);
  const [totalPage, setTotalPage] = useState<number>(1);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [graphData, setGraphData] = useState<GraphData[]>([]);
  const [complete, setComplete] = useState<number>(0);
  const [cancel, setCancel] = useState<number>(0);

  useEffect(() => {
    ProductsService.productList(currentPage, LIST_PAGE_LIMIT).then(
      (response) => {
        const result = response.data as ProductListResponse;
        setItems(result.items);
        setTotalPage(Math.ceil(result.total / LIST_PAGE_LIMIT));
        console.log(result);
      }
    );
  }, [currentPage]);

  const onClickDownload = () => {
    setVisible(true);
    StatsService.download(
      Utils.convertDateToTime(date!),
    ).then(async (response) => {
      const result = response.data as UrlResponse;
      if (result.result === RESULT_CODE.SUCCESS) {
        window.open(result.url);
      }

      setVisible(false);
    });
  };

  const onClickTable = (productId: number) => {
    StatsService.product(productId).then((response) => {
      const result = response.data as ProductStatDto[];

      let data: GraphData[] = [];
      let complete = 0;
      let cancel = 0;
      for (const dto of result) {
        data.push({
          name: dto.day,
          complete: dto.complete,
          cancel: Math.abs(dto.cancel)
        });
        complete += dto.complete;
        cancel += dto.cancel;
      }
      setGraphData(data);
      setComplete(complete);
      setCancel(cancel);
      console.log(result);
    });
  };

  const rows = items.map((item) => (
    <tr key={item.id} onClick={() => onClickTable(item.id)}>
      <td>{item.uuid}</td>
      <td>{item.title}</td>
      <td>{PRODUCT_TYPE_LABELS[item.type]}</td>
      <td>{item.artist.name}</td>
      <td>{item.amount.origin}</td>
      <td>{item.amount.amount}</td>
      <td>{item.publish ? "공개" : "비공개"}</td>
    </tr>
  ));

  return (
    <>
      {visible && (
        <Overlay blur={15} center>
          <Title order={3}>업로드 중</Title>
        </Overlay>
      )}
      <Title order={1}>상품 목록</Title>
      <Space h="md" />
      <Flex mih={50} gap="md" justify="flex-end" align="end" wrap="wrap">
        <MonthPickerInput
          label="기준 월 선택"
          placeholder="전월 21일~당월 20일"
          miw={200}
          locale="ko"
          value={date}
          onChange={setDate}
          valueFormat="YYYY/MM"
        />
        <Button onClick={onClickDownload}>다운로드</Button>
      </Flex>
      <Space h="xl" />
      <Table>
        <thead>
          <tr>
            <th>상품번호</th>
            <th>상품명</th>
            <th>타입</th>
            <th>아티스트</th>
            <th>가격</th>
            <th>할인가</th>
            <th>공개</th>
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </Table>
      <Space h="md" />
      <Pagination
        position="center"
        value={currentPage}
        onChange={setCurrentPage}
        total={totalPage}
      />
      <Space h="md" />
      <Flex gap={"md"} align={"center"}>
        <Text>주문완료 : {complete}개</Text>
        <Text>주문취소 : {Math.abs(cancel)}개</Text>
      </Flex>
      <Space h="md" />
      <BarChart
        width={1000}
        height={300}
        data={graphData}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="1 1" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar dataKey="complete" fill="#8884d8" />
        <Bar dataKey="cancel" fill="#d88488" />
      </BarChart>
    </>
  );
}
