import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Flex, Pagination, Space, Table, Title } from "@mantine/core";
import { ContentsService } from "services/ContentsService";
import { ScheduleListResponse, ScheduleSimpleDto } from "dto/ScheduleDto";
import { Utils } from "utils/Utils";
import { SCHEDULE_TYPE_LABELS } from "constants/ScheduleType";

const LIST_PAGE_LIMIT = 10;
export default function ScheduleListPage() {
  const navigate = useNavigate();

  const [items, setItems] = useState<ScheduleSimpleDto[]>([]);
  const [totalPage, setTotalPage] = useState<number>(1);
  const [currentPage, setCurrentPage] = useState<number>(1);

  useEffect(() => {
    ContentsService.scheduleList(currentPage, LIST_PAGE_LIMIT).then(
      (response) => {
        const result = response.data as ScheduleListResponse;
        setItems(result.items);
        setTotalPage(Math.ceil(result.total / LIST_PAGE_LIMIT));
        console.log(result);
      }
    );
  }, [currentPage]);

  const onClickCreate = () => {
    navigate("-1");
  };

  const onClickTable = (scheduleId: number) => {
    navigate("" + scheduleId);
  };

  const convertScheduleTime = (item: ScheduleSimpleDto) => {
    const time = Utils.convertTimeToDate(item.time);
    if (item.all_day) return Utils.convertDateToYYMMDD(time);

    return Utils.convertDateToYYMMDDHHmm(time);
  };

  const rows = items.map((item) => (
    <tr key={item.id} onClick={() => onClickTable(item.id)}>
      <td>{item.id}</td>
      <td>{item.artist.name}</td>
      <td>{SCHEDULE_TYPE_LABELS[item.type]}</td>
      <td>{item.title}</td>
      <td>{convertScheduleTime(item)}</td>
      <td>
        {Utils.convertDateToYYMMDD(Utils.convertTimeToDate(item.created))}
      </td>
      <td>{item.publish ? "공개" : "비공개"}</td>
    </tr>
  ));

  return (
    <>
      <Title order={1}>스케쥴</Title>
      <Space h="md" />
      <Flex mih={50} gap="md" justify="flex-end" align="center" wrap="wrap">
        <Button onClick={onClickCreate}>작성하기</Button>
      </Flex>
      <Space h="md" />
      <Table>
        <thead>
          <tr>
            <th>ID</th>
            <th>아티스트</th>
            <th>종류</th>
            <th>제목</th>
            <th>스케쥴</th>
            <th>등록일</th>
            <th>사용</th>
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </Table>
      <Space h="md" />
      <Pagination
        position="center"
        value={currentPage}
        onChange={setCurrentPage}
        total={totalPage}
      />
    </>
  );
}
