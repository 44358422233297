import React, { useEffect, useRef } from "react";
import ReactQuill from "react-quill";
import { CommonService } from "../services/CommonService";
import { UploadImageResponse } from "../dto/CommonDto";

interface Props {
  value: string;
  onChange: (value: string) => void;
}

const modules = {
  toolbar: [
    [{ font: [] }],
    [{ size: ["small", false, "large", "huge"] }], // custom dropdown
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    [{ color: [] }, { background: [] }], // dropdown with defaults from theme
    ["bold", "italic", "underline", "strike", "blockquote"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    ["link", "image"],
    ["clean"],
  ],
};

const formats = [
  "font",
  "size",
  "header",
  "color",
  "background",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
];

const CustomEditor = ({ value, onChange }: Props) => {
  const quillRef = useRef(null);

  useEffect(() => {
    // @ts-ignore
    quillRef.current
      .getEditor()
      .getModule("toolbar")
      .addHandler("image", imageHandler);
  }, [quillRef]);

  // 이미지 처리를 하는 핸들러
  const imageHandler = () => {
    console.log("에디터에서 이미지 버튼을 클릭하면 이 핸들러가 시작됩니다!");

    // 1. 이미지를 저장할 input type=file DOM을 만든다.
    const input = document.createElement("input");
    // 속성 써주기
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();

    input.onchange = async () => {
      if (!input.files || !input?.files?.length || !input?.files?.[0]) return;

      const file = input.files[0];
      const formData = new FormData();

      formData.append("file", file);
      try {
        CommonService.uploadImage(formData).then((response) => {
          const result = response.data as UploadImageResponse;

          console.log(result);
          const IMG_URL = result.url;
          // @ts-ignore
          const editor = quillRef?.current?.getEditor(); // 에디터 객체 가져오기
          const range = editor.getSelection();
          editor.insertEmbed(range.index, "image", IMG_URL);
        });
      } catch (error) {
        console.log("실패했어요ㅠ");
      }
    };
  };

  return (
    <>
      <ReactQuill
        ref={quillRef}
        style={{ height: "400px", margin: "4px" }}
        theme="snow"
        modules={modules}
        formats={formats}
        value={value}
        onChange={onChange}
        placeholder="내용을 입력하세요."
      />
    </>
  );
};

export default CustomEditor;
