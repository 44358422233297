import { Button, Group, NumberInput, Space, TextInput } from "@mantine/core";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
// import "App.css";
import { CommonResponse } from "dto/CommonDto";
import { RESULT_CODE } from "constants/ResultCode";
import { DeliveryAppendDto } from "dto/DeliveryDto";
import { USER_ROLE } from "constants/UserRole";
import { Context as AuthContext } from "contexts/AuthContext";
import { DeliveryService } from "services/DeliveryService";

export default function DeliveryDetailPage() {
  const navigate = useNavigate();

  const { deliveryId } = useParams();

  const {
    state: { role },
  } = useContext(AuthContext);

  const [currentId, setCurrentId] = useState<number>(-1);

  const [title, setTitle] = useState<string>("");
  const [first, setFirst] = useState<number>(0);
  const [last, setLast] = useState<number>(0);
  const [price, setPrice] = useState<number>(0);

  useEffect(() => {
    const id = parseInt(deliveryId!);
    setCurrentId(id);

    if (id > 0) {
      DeliveryService.delivery(id).then((response) => {
        const result = response.data as DeliveryAppendDto;
        if (result) {
          setTitle(result.title);
          setFirst(result.first);
          setLast(result.last);
          setPrice(result.price);
        }
        console.log(result);
      });
    }
  }, []);

  const onClickBack = () => {
    navigate(-1);
  };

  const handleSave = () => {
    if (!title) {
      return;
    }

    if (first <= 0 || last <= 0) {
      return;
    }

    if (first > last) {
      return;
    }

    DeliveryService.saveDelivery({
      id: currentId,
      title: title,
      first: first,
      last: last,
      price: price,
    }).then((response) => {
      const result = response.data as CommonResponse;
      if (result.result === RESULT_CODE.SUCCESS) {
        navigate(-1);
      }
    });
  };

  const onClickSave = () => {
    handleSave();
  };

  const onClickModify = () => {
    handleSave();
  };

  const onClickDelete = () => {
    DeliveryService.deleteDelivery(currentId).then((response) => {
      const result = response.data as CommonResponse;
      if (result.result === RESULT_CODE.SUCCESS) {
        navigate(-1);
      }
    });
  };

  const renderButtons = () => {
    if (currentId > 0) {
      return (
        <Group position="apart">
          <Button onClick={onClickModify}>수정하기</Button>
          {role === USER_ROLE.MASTER ? (
            <Button onClick={onClickDelete}>삭제하기</Button>
          ) : (
            <></>
          )}
        </Group>
      );
    }

    return (
      <Group position="apart">
        <Button onClick={onClickSave}>저장하기</Button>
      </Group>
    );
  };

  return (
    <>
      <Button color="gray" onClick={onClickBack}>
        목록으로
      </Button>
      <Space h="xl" />
      <TextInput
        label="제목"
        placeholder="제목"
        value={title}
        onChange={(event) => setTitle(event.currentTarget.value)}
      />
      <NumberInput
        label="처음 우편 코드"
        value={first}
        onChange={(value) => (value === "" ? setFirst(0) : setFirst(value))}
      />
      <Space h="xl" />
      <NumberInput
        label="마지막 우편 코드"
        value={last}
        onChange={(value) => (value === "" ? setLast(0) : setLast(value))}
      />
      <Space h="xl" />
      <NumberInput
        label="비용"
        value={price}
        onChange={(value) => (value === "" ? setPrice(0) : setPrice(value))}
      />
      <Space h="xl" />
      {renderButtons()}
    </>
  );
}
